<script>
import moment from 'moment';
import Layout from '@/views/layouts/main.vue';
import { cmsService as service } from '@/service';
import echarts from 'echarts/dist/echarts';
import { uikit } from '@/utils';
// import { option1 } from './echartsOptions'
export default {
  name: 'DriveChainTool',
  components: {
    Layout,
  },
  props: ['dataType'],
  data() {
    return {
      atlasList: [
        {
          name: '趋势图',
          value: 'trend',
        },
        {
          name: '波形图',
          value: 'waveform',
        },

        {
          name: '频谱图',
          value: 'spectrum',
        },
        {
          name: '包络图',
          value: 'envelope',
        },

        {
          name: '时域图',
          value: 'timeDomain',
        },
        {
          name: '转速',
          value: 'speed',
        },
      ],
      atlasCur: 'trend',
      selectCoskey: '',
      chart: null,
      cludesType: ['主轴承', '齿轮箱', '发电机'],
      trendCharts: null,
      waveCharts: null,
      spectrumEchart: null,
      envelopeEchart: null,
      timeDomainEchart: null,
      twoCharts: null,
      speedEchart: null,
      frequencyList: ['5120', '51200', '12800', '25600'],
      waveDatas: '',
      isFilter: true,
      colors: ['#1693a7', '#f38630', '#fe4365', '#edc951', '#99173c', '#88c425', '#e6ac27', '#ff00aa', '#1b325f'],
      lastClickSeriesIndex: -1,
      lastClickDataIndex: -1,
      checkedTrendPointData: {},
      speedDatas: [],
    };
  },
  computed: {
    headerOptions() {
      return this.$store.state.cms.toolHeaderOptions;
    },
  },
  watch: {
    headerOptions(newVal, oldVal) {
      console.log(newVal, 'newVal');
      this.initEcharts();
      this.initTimeDomainCharts();
      this.initTwoCharts();
      const chartOption = this.twoCharts.getOption();
      chartOption.graphic = [];
      this.twoCharts.setOption(chartOption, true);
      this.checkedTrendPointData = {};
      this.fetchCMSDatas(newVal);
    },
    isFilter(newVal, oldVal) {
      this.fetchCMSDatas(this.headerOptions);
      if (Object.keys(this.checkedTrendPointData).length > 0) {
        const {
          cosKey, partAscription, partAttributeName, speed,
        } = this.checkedTrendPointData;
        const option = {
          isFilter: newVal,
          frequency: this.headerOptions.frequency.toString(),
          filepath: cosKey,
          partascription: partAscription,
          partattributename: partAttributeName,
          filterrange: this.headerOptions.filterRange,
        };
        const twoChartOption = {
          ...option,
          farmcode: this.headerOptions.farmCode,
          turbinename: this.headerOptions.turbineName,
          speed,
        };
        this.randerTwoCharts(twoChartOption);
        this.getCmsTimeDomainDatas(option);
      } else {
        this.initTimeDomainCharts();
        this.initTwoCharts();
      }
    },
  },
  mounted() {
    this.initEcharts();
    this.initTimeDomainCharts();
    this.initTwoCharts();
    window.onresize = () => {
      this.trendCharts.resize();
      this.timeDomainEchart.resize();
      this.speedEchart.resize();
      this.twoCharts.resize();
    };
  },
  methods: {
    getPartInfo(seriesName) {
      const headerOptionsPointName = this.headerOptions.pointName;
      let partInfo = {};
      if (seriesName.indexOf('发电机') >= 0) {
        partInfo = headerOptionsPointName.find((item) => item.part_name === '发电机');
      }
      if (seriesName.indexOf('齿轮箱') >= 0) {
        partInfo = headerOptionsPointName.find((item) => item.part_name === '齿轮箱');
      }
      if (seriesName.indexOf('主轴承') >= 0) {
        partInfo = headerOptionsPointName.find((item) => item.part_name === '主轴承');
      }
      return partInfo;
    },
    fetchCMSDatas(options) {
      const option = JSON.parse(JSON.stringify(options));
      if (!option.pointName.length) {
        return;
      }
      this.trendCharts.showLoading();
      option.pointName = option.pointName.map((item) => item.point_name);
      service.fetchCmsAnalyTrend(option).then((results) => {
        this.trendCharts.hideLoading();
        if (!(results && results.length)) {
          uikit.error('未查询到数据！');
          return;
        }
        this.speedDatas = results.map((item) => {
          return {
            samplingTime: moment(item.dataTime).format('YYYY-MM-DD HH:mm:ss'),
            speed: item.speed,
          };
        });
        this.fecthSpeedDatas();
        const seriesDatas = [];
        this.headerOptions.pointName.forEach((point, index) => {
          const name = this.isFilter && this.dataType === 'drivechain' ? `${point.point_name} ${options.filterRange}` : point.point_name;
          const obj = {
            name,
            data: [],
            type: 'line',
            color: [this.colors[index]],
            itemStyle: {
              normal: {
                color: this.colors[index],
                lineStyle: {
                  color: this.colors[index], // 改变折线颜色
                },
              },
            },
          };
          results.filter((result) => result.pointName === point.point_name).forEach((item) => {
            obj.data.push({
              value: this.isFilter ? item.rmsVdi : item.rms,
              ...item,
            });
          });
          seriesDatas.push(obj);
        });
        const xAxisData = seriesDatas.find((seriesData) => seriesData.data.length > 0);
        const categorys = xAxisData.data.map((item) => {
          const time = moment(item.dataTime).format('YYYY-MM-DD HH:mm:ss');
          return time;
        });
        this.trendCharts.off('click');
        this.trendCharts.setOption({
          title: {
            text: '测点趋势',
          },
          legend: {
            left: 'center',
            formatter: (value) => {
              return value;
            },
          },
          xAxis: {
            name: '时间',
            data: categorys,
          },
          series: seriesDatas,
        });
        this.trendCharts.on('click', (params) => {
          const matchData = this.getPartInfo(params.seriesName);
          this.checkedTrendPointData = {
            ...params.data,
            samplingTime: moment(params.name).format('YYYY-MM-DD HH:mm:ss'),
            partAscription: matchData?.part_name,
            partAttributeName: matchData?.part_attribute_value,
            speed: params.value,
          };
          // 转速图对应数据高亮
          const curSpeedData = this.speedDatas.find((data) => data.samplingTime === this.checkedTrendPointData.samplingTime);
          if (curSpeedData) {
            this.speedEchart.setOption({
              series: [
                {
                  markPoint: {
                    symbol: 'circle',
                    symbolSize: 10,
                    data: [{
                      coord: [moment(curSpeedData.samplingTime).format('YYYY-MM-DD HH:mm:ss'), curSpeedData.speed], // 5表示x轴，71表示y轴
                    }],
                  },
                },
              ],
            });
          }
          if (this.lastClickSeriesIndex >= 0) {
            const lastClickData = seriesDatas[this.lastClickSeriesIndex].data[this.lastClickDataIndex];
            lastClickData.symbol = 'emptyCircle';
            lastClickData.symbolSize = 4;
            this.trendCharts.setOption({
              series: seriesDatas,
            });
          }
          const seriesData = seriesDatas[params.seriesIndex];
          const data = seriesData.data[params.dataIndex];
          this.lastClickSeriesIndex = params.seriesIndex;
          this.lastClickDataIndex = params.dataIndex;
          data.symbol = 'circle';
          data.symbolSize = 10;
          this.trendCharts.setOption({
            series: seriesDatas,
          });
          // this.selectFilePath = params.data.cosKey
          const option = {
            frequency: this.headerOptions.frequency.toString(),
            filepath: this.checkedTrendPointData.filePath,
            isfilter: this.isFilter,
            partascription: this.checkedTrendPointData.partAscription,
            partattributename: this.checkedTrendPointData.partAttributeName,
            filterrange: this.headerOptions.filterRange,
          };
          const twoChartOption = {
            ...option,
            farmcode: this.headerOptions.farmCode,
            turbinename: this.headerOptions.turbineName,
            speed: this.checkedTrendPointData.speed,
          };
          this.randerTwoCharts(twoChartOption);
          this.getCmsTimeDomainDatas(option);
        });
      });
    },

    fecthSpeedDatas(options) {
      // const option = JSON.parse(JSON.stringify(options));
      // option.pointName = ['发电机转速'];
      // service.fetchCMSDatas(option).then((result) => {
      //   if (!(result && result.length)) {
      //     uikit.error('未查询到数据！');
      //     return;
      //   }
      //   this.speedDatas = result;
      //   console.log(this.speedDatas, 'this.speedDatas');
      //   const categorys = this.speedDatas.map((item) => { return moment(item.samplingTime).format('YYYY-MM-DD HH:mm:ss'); });

      // });
      this.speedEchart.setOption({
        title: {
          text: '转速图',
        },
        xAxis: {
          type: 'category',
          name: '时间',
          data: this.speedDatas.map((item) => item.samplingTime),
        },
        series: [{
          itemStyle: {
            normal: {
              lineStyle: {
                color: '#0060aa',
              },
            },
          },
          name: '',
          type: 'line',
          smooth: false,
          data: this.speedDatas.map((item) => item.speed),
        }],
      });
    },

    initEcharts() {
      this.trendCharts = echarts.init(document.getElementById('trendEchart'));
      // this.waveCharts = echarts.init(document.getElementById('waveEchart'))
      this.speedEchart = echarts.init(document.getElementById('speedEchart'));
      // 图表的配置项和数据
      const option = {
        title: {
          left: '10px',
          top: 0,
          text: '测点趋势',
          textStyle: {
            color: '#333',
            fontWeight: 'normal',
            fontSize: 13,
            height: 20,
            lineHeight: 20,
          },
        },
        legend: {
          itemGap: 10,
        },
        grid: {
          top: 40,
          right: 70,
          left: 70,
        },
        toolbox: {
          show: false,
          feature: {
            saveAsImage: {},
          },
        },
        tooltip: {
          trigger: 'axis',
          position(pt) {
            return [pt[0] + 15, '10%'];
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            color: '#d1d8e2',
            lineStyle: {
              type: 'solid',
              color: '#d1d8e2', // 左边线的颜色
              width: '1', // 坐标线的宽度
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#e4e4e4',
              type: 'dotted',
            },
          },
          name: '',
          axisLabel: {
            formatter: '{value} ',
            color: '#666669',
          },
          data: [],
        },
        yAxis: {
          name: '',
          type: 'value',
          axisLabel: {
            formatter: '{value} ',
            color: '#666669',
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#e4e4e4',
              type: 'dotted',
            },
          },
          axisLine: {
            color: '#d1d8e2',
            lineStyle: {
              type: 'solid',
              color: '#d1d8e2', // 左边线的颜色
              width: '1', // 坐标线的宽度
            },
          },
          axisPointer: {
            snap: true,
          },
          data: [],
        },
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 100,
        }, {
          start: 0,
          end: 100,
          height: 20,
        }],
        series: [{
          itemStyle: {
            normal: {
              lineStyle: {
                color: '#0060aa',
              },
            },
          },
          name: '',
          type: 'line',
          smooth: false,
          data: [],
        }],
      };
      // 使用刚指定的配置项和数据显示图表。
      this.trendCharts.setOption(option, true);
      option.title.text = '转速图';
      option.series[0].name = '转速';
      // this.waveCharts.setOption(option, true)
      this.speedEchart.setOption(option, true);
    },

    initTimeDomainCharts() {
      this.timeDomainEchart = echarts.init(document.getElementById('timeDomainEchart'));
      let base = +new Date(1968, 9, 3);
      const oneDay = 24 * 3600 * 1000;
      const date = [];
      const data = [Math.random() * 300];

      for (let i = 1; i < 20000; i += 1) {
        const now = new Date(base += oneDay);
        date.push([now.getFullYear(), now.getMonth() + 1, now.getDate()].join('/'));
        data.push(Math.round((Math.random() - 0.5) * 20 + data[i - 1]));
      }
      const option = {
        tooltip: {
          trigger: 'axis',
          position(pt) {
            return [pt[0], '10%'];
          },
        },
        title: {
          left: 10,
          top: 0,
          text: '时域图',
          textStyle: {
            color: '#333',
            fontWeight: 'normal',
            fontSize: 13,
            height: 20,
            lineHeight: 20,
          },
        },
        toolbox: {
          show: false,
        },
        grid: {
          top: 40,
          right: 70,
          left: 70,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          interval: 6,
          axisLabel: {
            formatter(value, index) {
              return parseFloat(value).toFixed(2);
            },
            color: '#666669',
          },
          axisLine: {
            color: '#d1d8e2',
            lineStyle: {
              type: 'solid',
              color: '#d1d8e2', // 左边线的颜色
              width: '1', // 坐标线的宽度
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#e4e4e4',
              type: 'dotted',
            },
          },
          data: [],
        },
        yAxis: {
          type: 'value',
          min: 'dataMin',
          max: 'dataMax',
          boundaryGap: [0, '100%'],
          axisLine: {
            color: '#d1d8e2',
            lineStyle: {
              type: 'solid',
              color: '#d1d8e2', // 左边线的颜色
              width: '1', // 坐标线的宽度
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#e4e4e4',
              type: 'dotted',
            },
          },
          axisLabel: {
            formatter(value, index) {
              return parseFloat(value).toFixed(3);
            },
            color: '#666669',
          },
        },
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 100,
        }, {
          start: 0,
          end: 100,
          height: 20,
        }],
        series: [
          {
            name: '时域数据',
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            data: [],
          },
        ],
      };
      this.timeDomainEchart.setOption(option);
    },
    initTwoCharts() {
      this.twoCharts = echarts.init(document.getElementById('twoCharts'));
      const option = {
        backgroundColor: '#ffffff',
        title: [
          {
            left: '10px',
            top: 0,
            text: '频谱图',
            textStyle: {
              color: '#333',
              fontWeight: 'normal',
              fontSize: 13,
              height: 20,
              lineHeight: 20,
            },
          }, {
            left: '10px',
            top: '43%',
            text: '包络图',
            textStyle: {
              color: '#333',
              fontWeight: 'normal',
              fontSize: 13,
              height: 20,
              lineHeight: 20,
            },
          },
        ],
        grid: [{ // 这是控制上面的bar
          height: '30%',
          top: 40,
          right: 70,
          left: 70,
          containLabel: false,
        },
        { // 控制下面的line
          right: 70,
          left: 70,
          top: '53%',
          height: '30%',
          containLabel: false,
        }],
        dataZoom: [{
          type: 'inside',
          realtime: true,
          start: 0,
          end: 100,
          xAxisIndex: [0, 1],
        }, {
          show: true,
          realtime: true,
          start: 0,
          end: 100,
          xAxisIndex: [0, 1],
          height: 20,
        }],
        xAxis: [{ // x轴要写两份
          type: 'category',
          boundaryGap: 0,
          interval: 6,
          axisLine: {
            color: '#d1d8e2',
            lineStyle: {
              type: 'solid',
              color: '#d1d8e2', // 左边线的颜色
              width: '1', // 坐标线的宽度
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#e4e4e4',
              type: 'dotted',
            },
          },
          axisLabel: {
            formatter(value, index) {
              return parseFloat(value).toFixed(2);
            },
            color: '#666669',
          },
          data: [],
        },
        {
          type: 'category',
          gridIndex: 1, // 所在的 grid 的索引，默认位于第一个 grid。
          boundaryGap: 0,
          interval: 6,
          axisLine: {
            color: '#d1d8e2',
            lineStyle: {
              type: 'solid',
              color: '#d1d8e2', // 左边线的颜色
              width: '1', // 坐标线的宽度
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#e4e4e4',
              type: 'dotted',
            },
          },
          axisLabel: {
            formatter(value, index) {
              return parseFloat(value).toFixed(2);
            },
            color: '#666669',
          },
        },
        ],
        yAxis: [{ // y轴也是
          type: 'value',
          min: 'dataMin',
          max: 'dataMax',
          boundaryGap: [0, '100%'],
          axisLine: {
            color: '#d1d8e2',
            lineStyle: {
              type: 'solid',
              color: '#d1d8e2', // 左边线的颜色
              width: '1', // 坐标线的宽度
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#e4e4e4',
              type: 'dotted',
            },
          },
          axisLabel: {
            formatter(value, index) {
              return parseFloat(value).toFixed(3);
            },
            color: '#666669',
          },
          data: [],
        },
        {
          type: 'value',
          gridIndex: 1,
          min: 'dataMin',
          max: 'dataMax',
          boundaryGap: [0, '100%'],
          axisLine: {
            color: '#d1d8e2',
            lineStyle: {
              type: 'solid',
              color: '#d1d8e2', // 左边线的颜色
              width: '1', // 坐标线的宽度
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#e4e4e4',
              type: 'dotted',
            },
          },
          axisLabel: {
            formatter(value, index) {
              return parseFloat(value).toFixed(3);
            },
            color: '#666669',
          },
        },
        ],
        tooltip: {
          trigger: 'axis',
        },
        series: [
          {
            name: '频谱数据',
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              normal: {
                color: '#fd9614',
              },
            },
            data: [],
            markLine: {
              symbol: 'none',
              silent: true,
              data: [],
            },
          },
          {
            name: '包络数据',
            type: 'line',
            xAxisIndex: 1,
            yAxisIndex: 1,
            symbol: 'none',
            sampling: 'lttb',
            data: [],
            itemStyle: {
              normal: {
                color: '#781f75',

              },
            },
            markLine: {
              symbol: 'none',
              silent: true,
              data: [],
            },
          },
        ],
      };
      this.twoCharts.setOption(option, true);
    },

    getWaveDatas(coskey, frequency) {
      const start = new Date();
      this.waveCharts.showLoading();
      return service.fetchCmsWaveformDatas(coskey, frequency);
    },
    getCmsEnvelopeDatas(option) {
      return service.fetchEnvelopeDatas(option);
    },
    randerTwoCharts(option) {
      this.twoCharts.showLoading();
      option.speed = option.speed.toString();
      Promise.all([this.getCmsSpectrumDatas(option), this.getCmsEnvelopeDatas(option)]).then((results) => {
        const errorData = results.filter((result) => result.code && result.code === '1');
        if (errorData && errorData.length) {
          this.twoCharts.hideLoading();
          uikit.error(errorData[0].output);
          return;
        }
        const lineColors = ['#009ad6', '#88c425', '#f173ac', '#1d953f', '#fedcbd', '#99173c'];
        const spectrumMarkLineDatas = [];
        const spectrumGraphicDatas = [];
        const spectrumOrderdatas = results[0].orderdata ? JSON.parse(results[0].orderdata) : [];
        spectrumOrderdatas.forEach((orderMap, index) => {
          const graphicObj = {
            type: 'text',
            z: 100,
            top: 20 * index,
            style: {
              text: orderMap.label.concat('\n'),
              fill: lineColors[index],
            },
          };
          spectrumGraphicDatas.push(graphicObj);
          // eslint-disable-next-line no-restricted-syntax
          for (const key in orderMap) {
            if (key !== 'label') {
              const obj = {
                lineStyle: { // 警戒线的样式  ，虚实  颜色
                  type: 'dashed',
                  color: lineColors[index],
                  width: 1,
                },
                label: {
                  position: 'insideEndTop',
                  formatter: key,
                  distance: [20, 2],
                },
                xAxis: orderMap[key].toString(),
              };
              spectrumMarkLineDatas.push(obj);
            }
          }
        });
        const envelopeMarkLineDatas = [];
        const envelopeGraphicDatas = [];
        const envelopeOrderdatas = results[1].orderdata ? JSON.parse(results[1].orderdata) : [];
        envelopeOrderdatas.forEach((orderMap, index) => {
          envelopeGraphicDatas.push({
            type: 'text',
            z: 100,
            top: 20 * index,
            style: {
              text: orderMap.label.concat('\n'),
              fill: lineColors[index],
            },
          });
          // eslint-disable-next-line no-restricted-syntax
          for (const key in orderMap) {
            if (key !== 'label') {
              const obj = {
                lineStyle: { // 警戒线的样式  ，虚实  颜色
                  type: 'dashed',
                  color: lineColors[index],
                  width: 1,
                },
                name: key,
                label: {
                  position: 'insideEndTop',
                  formatter: '{b}',
                  distance: [20, 2],
                },
                xAxis: orderMap[key].toString(),
              };
              envelopeMarkLineDatas.push(obj);
            }
          }
        });
        this.twoCharts.setOption({
          xAxis: [{
            data: JSON.parse(results[0].spectrum.xAxis),
          }, {
            data: JSON.parse(results[1].envelope.xAxis),
          }],
          graphic: [
            {
              type: 'group',
              right: '10%',
              top: 28,
              children: spectrumGraphicDatas,
            },
            {
              type: 'group',
              right: '10%',
              top: '50%',
              children: envelopeGraphicDatas,
            }],
          series: [{
            data: JSON.parse(results[0].spectrum.yAxis),
            markLine: {
              symbol: 'none',
              silent: true,
              data: spectrumMarkLineDatas,
            },
          }, {
            data: JSON.parse(results[1].envelope.yAxis),
            markLine: {
              symbol: 'none',
              silent: true,
              data: envelopeMarkLineDatas,
            },
          }],
        });
        this.twoCharts.hideLoading();
      }).catch((err) => {
        console.log(err);
        this.twoCharts.hideLoading();
      });
    },
    getCmsSpectrumDatas(option) {
      return service.fetchCmsSpectrumDatas(option);
    },
    getCmsTimeDomainDatas(option) {
      this.timeDomainEchart.showLoading();
      const start = new Date();
      service.fetchTimeDomainDatas(option).then((result) => {
        this.timeDomainEchart.hideLoading();
        if (result.code && result.code === '1') {
          uikit.error(result.output);
          return;
        }
        const end = new Date();

        this.timeDomainEchart.setOption({
          title: {
            text: '时域图',
          },
          xAxis: {
            data: JSON.parse(result.xAxis),
          },
          series: [
            {
              name: '时域数据',
              type: 'line',
              itemStyle: {
                color: '#44992c',
              },
              data: JSON.parse(result.yAxis),
            },
          ],
        });
        const end2 = new Date();
      });
    },
    clearEcharts() {
      const option = {
        xAxis: {
          data: [],
        },
        series: [
          {
            type: 'line',
            itemStyle: {
              color: '#44992c',
            },
            data: [],
          },
        ],
      };
      this.initTwoCharts();
      this.timeDomainEchart.setOption(option);
    },

  },
};
</script>

<template>
  <Layout>
    <div class="tool-box">
      <div class="tool-content">
        <div v-if="dataType === 'drivechain'" class="switchBox">
          <span class="mr-3">
            是否滤波：
            <el-switch
              v-model="isFilter"
              active-color="#13ce66"
              inactive-color="#ff4949"
            />
          </span>
          <!-- <span>
            <el-button size="small" type="primary" @click="clearEcharts">
              清空图表
            </el-button>
          </span> -->
        </div>
        <div id="trendEchart" class="echarts-box" />
        <div class="echarts-box">
          <div id="timeDomainEchart" style="height:100%" />
        </div>
        <div id="twoCharts" class="echarts-box h450" />
        <div id="speedEchart" class="echarts-box" />
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" scoped>
.tool-top{
  height:30px;
  line-height: 30px;
  text-align: right;
  font-size: 13px;
  span{
    display: inline-block;
    padding: 0 8px;
    border: 1px solid #e4e7ed;
    border-left: none;
    &:first-of-type{
      border-left: 1px solid #e4e7ed;
    }
    &.active{
      color:#fff;
      background:#8dbf42 ;
      border:1px solid #8dbf42
    }
  }
}
.echarts-box{
  height: 200px;
  width: 100%;
  &.h450{
    height: 450px;
  }
}
.switchBox{
  text-align: right;
  span:first-of-type{
    display: inline-block;
    margin-right: 15px;
  }
}
</style>
