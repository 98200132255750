<script>
import { reportService as service } from '@/service';
import CosLink from '@/components/CosLink';
import moment from 'moment';
import Pagination from '@/components/Pagination';

export default {
  name: 'TurbineExplorer',
  components: {
    CosLink,
    Pagination,
  },
  props: ['farmCode'],
  data: () => {
    return {
      isLoading: false,
      orderModel: {
        field: '',
        sort: '',
      },
      pageData: {
        data: [],
        page: 1,
        limit: 20,
        total: 0,
      },
    };
  },
  computed: {
    farm() {
      const farms = this.$store.state.farm.list.filter((item) => item.pinyinCode === this.farmCode);
      return farms.length > 0 ? farms[0] : null;
    },
  },
  watch: {
    farmCode(n, o) {
      const farms = this.$store.state.farm.list.filter((ent) => ent.pinyinCode === n);
      this.farm = farms.length > 0 ? farms[0] : null;
      this.doSearch();
    },
  },

  mounted() {
    this.doSearch();
  },
  methods: {
    doSearch(orderObj) {
      const order = orderObj ? { order_by: orderObj.field, sort: orderObj.sort } : { order_by: '', sort: '' };
      const option = {
        order,
        type: 'month',
        farmName: this.farm.farmName,
        page: this.pageData.page,
        limit: this.pageData.limit,
      };
      this.isLoading = true;
      service.searchMonthlyReportByFarm(option).then((res) => {
        this.isLoading = false;
        this.pageData = res;
        this.pageData.data = this.pageData.data.map((item) => {
          item.analyzingReports = item.analyzingReports ? JSON.parse(item.analyzingReports) : '';
          item.updatedAt = moment(item.updatedAt).format('YYYY-MM-DD hh:mm:ss');
          return item;
        });
      });
    },
    gotoPage(i) {
      this.pageData.page = i;
      this.doSearch();
    },
    setOrder(field) {
      if (this.orderModel.field === field) {
        if (this.orderModel.sort === 'asc') {
          this.orderModel.sort = 'desc';
        } else {
          this.orderModel.sort = 'asc';
        }
      } else {
        this.orderModel.field = field;
        this.orderModel.sort = 'desc';
      }
      this.doSearch(this.orderModel);
    },
    setOrderStyle(filed) {
      if (this.orderModel.field === filed) {
        if (this.orderModel.sort === 'asc') {
          return 'sorting_asc';
        }
        return 'sorting_desc';
      }
      return '';
    },
    toPercent(point) {
      if (point) {
        let str = Number(point * 100).toFixed(1);
        str += '%';
        return str;
      }
      return point;
    },
  },
};
</script>

<template>
  <div class="explorer-container">
    <div class="widget-content widget-content-area explorer-content">
      <div v-loading="isLoading" class="table-responsive table-sticky-wrapper">
        <table class="table table-bordered dataTable">
          <thead>
            <tr>
              <th rowspan="2">
                <span style="width:100px">月份</span>
              </th>
              <th colspan="8">
                <span style="width:70px">故障分析</span>
              </th>
              <th colspan="7">
                <span style="width:70px">分析报告</span>
              </th>
              <th rowspan="2">
                <span style="width:180px">分析报告下载</span>
              </th>
            </tr>
            <tr>
              <th>
                <span style="width:70px">平均可利用率</span>
              </th>
              <th>
                <span style="width:70px">总停机时长(h)</span>
              </th>
              <th><span style="width:70px">总停机次数</span></th>
              <th><span style="width:70px">总停机台数</span></th>
              <th><span style="width:70px">平均停机时长(h)</span></th>
              <th><span style="width:70px">停机频次</span></th>
              <th><span style="width:70px">平均无故障时间(h)</span></th>
              <th><span style="width:70px">平均恢复时间(h)</span></th>
              <th><span style="width:70px">平均风速(m/s)</span></th>
              <th><span style="width:100px">全场累计发电量平均值（万度）</span></th>
              <th><span style="width:112px">本周期全场实际发电量均值（万度)</span></th>
              <th><span style="width:70px">事件记录完整率</span></th>
              <th><span style="width:70px">5min数据完整率</span></th>
              <th><span style="width:60px">风机台数</span></th>
              <th><span style="width:150px">报表生成日期</span></th>
              <!-- <th>结果汇总</th> -->
            </tr>
          </thead>
          <tbody v-if="pageData.data.length > 0">
            <tr v-for="(item, index) in pageData.data" :key="'row-' + index">
              <td>{{ item.dateString }}</td>
              <td>{{ toPercent(item.averageAvailability) }}</td>
              <td>{{ item.totalHaltTime }}</td>
              <td>{{ item.totalHaltCount }}</td>
              <td>{{ item.totalHaltTurbines }}</td>
              <td>{{ item.averageHaltTime }}</td>
              <td>{{ item.haltFrequency }}</td>
              <td>{{ item.mtbf }}</td>
              <td>{{ item.mttr }}</td>

              <td>{{ item.averageSpeed }}</td>
              <td>{{ item.totalPower }}</td>
              <td>{{ item.cyclePower }}</td>
              <td>{{ item.eventcom }}</td>
              <td>{{ item.historycom }}</td>
              <td>{{ item.turbineCount }}</td>
              <td>{{ item.updatedAt }}</td>
              <td class="text-center">
                <ul v-if="item.analyzingReports" class="table-controls pointer">
                  <li v-if="item.analyzingReports.error">
                    <a class="text-primary">
                      数据异常
                    </a>
                  </li>
                  <template v-else>
                    <!-- <div>{{ item.analyzingReports.length }}</div> -->
                    <li v-for="r in item.analyzingReports" :key="r.name" style="margin-right:3px">
                      <CosLink class="text-info" :name="r.name" :cos-key="`model_result${r.path}`" />
                    </li>
                  </template>
                </ul>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="19" style="background:transparent">
                <el-empty description="暂无数据" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination :page-data="pageData" :goto-page="gotoPage" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.layout{
  height: 100%;
  overflow: auto;
}
.explorer-container{
  padding: 0px 5px;
  height: 100%;
  .explorer-content{
    padding:10px;
    height: 100%;
  }
  .table-sticky-wrapper{
    height: calc(100% - 50px);
    margin-bottom: 10px;
  }
}

thead{
  tr{
    th{
      background: #fff;
    }
    &:first-child {
      th{
        position: sticky;
        top: -1px;
        z-index: 2;
        &:first-child {
          width: 120px;
          position: sticky;
          z-index: 3;
          background: #f4f3fb;
          left: -1px;
          &::before,
          &::after {
            bottom: 2em !important;
          }
        }
      }
    }
    &:last-child{
      th{
        position: sticky;
        top: 31px;
        z-index: 2;
      }
    }
    span{
      display: inline-block;
    }
  }
}
tbody{
  tr{
    td:nth-of-type(1){
      position: sticky;
      z-index: 2;
      background: #f4f3fb;
      left: -1px;
    }
  }
}
</style>
