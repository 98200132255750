<script>
import moment from 'moment';
import _ from 'lodash';
import { Viewer } from '@toast-ui/vue-editor';
// @toast-ui/editor名称和 @toast-ui/vue-editor不一致，会产生 import/no-extraneous-dependencies错误。因此禁用eslint
import "@toast-ui/editor/dist/toastui-editor-viewer.css"; // eslint-disable-line

import appConfig from '@/app.config';
import { uikit } from '@/utils';
import { protoTurbineService as service, farmService } from '@/service';
import dataTypes from './dataTypes.json';

export default {
  name: 'TurbineExplorer',
  page: {
    title: '样机-数据下载',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Viewer,
  },
  props: ['farmCode', 'checkedTurbine'],
  data: () => {
    return {
      isLoading: true,
      turbineList: [],
      turbineStatusList: [],
      showBasics: '1',
      currentDate: moment().add(-7, 'day').toDate(),
      selectedDataType: {
        type: 'all',
        name: '全部类型',
      },
      selectedTurbines: [],
      selectedTag: {},
      dateRange: [],
      peekResult: null,
      downloadStatus: null,
      progress: '',
      files: [],
      selectedTurbinesAllIschecked: false,
      dataTypes,
      tableData: [],
      momthDates: {},
      showMarkdown: false,
    };
  },
  computed: {
    currentMonthStr() {
      return moment(this.currentDate).format('YYYY-MM');
    },
    percentage() {
      const { progress, total } = this.downloadStatus;
      return Math.floor((progress / total) * 100);
    },
    downloadConditions() {
      return {
        dataType: this.selectedDataType.type,
        dateRange: this.dateRange,
        protoName: this.checkedTurbine.protoName,
        protoId: this.checkedTurbine.turbineId,
      };
    },
    // 限制daterange选择区间
    datePickerOptions() {
      return {
        disabledDate: (time) => {
          if (this.checkedTurbine.startTime && this.checkedTurbine.endTime) {
            return (
              time < new Date(this.checkedTurbine.startTime).getTime()
              || time > new Date(this.checkedTurbine.endTime).getTime()
            );
          }
          return false;
        },
      };
    },
  },
  watch: {
    checkedTurbine(newV, oldV) {
      if (Object.keys(newV).length > 0) {
        if (this.$refs.toastuiViewer) {
          this.$refs.toastuiViewer.invoke('setMarkdown', newV.content);
        }
        // 更新时间选择范围
        if (newV.startTime && newV.endTime) {
          this.dateRange = [newV.startTime, newV.endTime];
        }
        this.getDatas();
      }
    },
    downloadConditions(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.peekDownloadResult();
      }
    },
  },
  mounted() {
    this.dateRange = [this.checkedTurbine.startTime, this.checkedTurbine.endTime];
    this.getDatas();
  },
  methods: {
    daysOfMonth(month) {
      const monthDate = new Date(month);
      const days = new Date(monthDate.getFullYear(), monthDate.getMonth() + 1, 0).getDate();
      return days;
    },
    getDatas(fileType) {
      if (Object.keys(this.checkedTurbine).length === 0) {
        return;
      }
      const months = this.getMonth(this.checkedTurbine.startTime, this.checkedTurbine.endTime);
      const {
        pinyinCode, turbineName, startTime, endTime,
      } = this.checkedTurbine;
      if (!pinyinCode) {
        uikit.error('请选择风机！');
        return;
      }
      const options = {
        pinyinCode,
        turbineName,
        from: startTime,
        to: endTime,
        fileType,
      };
      service.fetchProtoTurbineData(options).then((result) => {
        this.tableData = result;
      });
    },
    computeTurbineStatus(ts) {
      if (!ts) {
        return 'empty';
      }
      let cosCount = 0;
      if (this.selectedDataType) {
        switch (this.selectedDataType.type) {
          case 'fault': {
            cosCount = _.get(ts, 'fault.count') || 0;
            return cosCount === 0 ? 'empty' : 'full';
          }
          case 'history': {
            cosCount = _.get(ts, 'history.count') || 0;
            return cosCount === 0 ? 'empty' : 'full';
          }
          case 'sec': {
            cosCount = _.get(ts, 'sec.count') || 0;
            return cosCount === 0 ? 'empty' : 'full';
          }
          case 'event': {
            cosCount = _.get(ts, 'event.count') || 0;
            return cosCount === 0 ? 'empty' : 'full';
          }
          case 'qd': {
            cosCount = _.get(ts, 'qd.count') || 0;
            if (cosCount === 0) {
              return 'empty';
            }
            if (cosCount >= 3) {
              return 'full';
            }
            return 'warning';
          }
          case 'all': {
            let turbineFileStatusArr = [];
            const faultFileStatus = _.get(ts, 'fault.count') ? 'empty' : 'full';
            const historyFileStatus = _.get(ts, 'history.count') ? 'empty' : 'full';
            const secFileStatus = _.get(ts, 'sec.count') ? 'empty' : 'full';
            const eventFileStatus = _.get(ts, 'event.count') ? 'empty' : 'full';
            let qdFileStatus;
            if (_.get(ts, 'qd.count')) {
              qdFileStatus = 'empty';
            } else if (_.get(ts, 'qd.count') >= 3) {
              qdFileStatus = 'full';
            } else {
              qdFileStatus = 'warning';
            }
            turbineFileStatusArr = [
              faultFileStatus,
              historyFileStatus,
              secFileStatus,
              eventFileStatus,
              qdFileStatus,
            ];
            if (turbineFileStatusArr.every((item) => item === 'full')) return 'full';
            if (turbineFileStatusArr.every((item) => item === 'empty')) return 'empty';
            if (turbineFileStatusArr.includes('empty') || turbineFileStatusArr.includes('warning')) return 'warning';
            break;
          }
          default: {
            return 'empty';
          }
        }
      }
      return 'empty';
    },
    downloadOptions() {
      let from = '';
      let to = '';
      if (this.dateRange.length) {
        const dateRanges = this.dateRange;
        from = dateRanges[0];
        to = dateRanges[dateRanges.length - 1];
      }
      const options = {
        from,
        to,
        turbineName: this.checkedTurbine.turbineName,
        pinyinCode: this.checkedTurbine.pinyinCode,
        protoName: this.checkedTurbine.protoName,
      };
      if (this.selectedDataType.type !== 'all') {
        options.fileTypes = [this.selectedDataType.type];
      }
      return options;
    },
    peekDownloadResult() {
      service.peekProtoCosFile(this.downloadOptions()).then((result) => {
        this.peekResult = result;
      });
    },
    downloadData() {
      if (this.peekResult && this.peekResult.count > 0) {
        service.downloadProtoCosFile(this.downloadOptions()).then((res) => {
          const { count, jobId } = res;
          if (jobId) {
            this.progress = `正在下载 ${0 / count}`;
            this.checkDownloadJob(jobId);
          } else {
            console.log(res);
            uikit.message('下载失败！');
          }
        });
      } else {
        uikit.message('没有文件可以下载！');
      }
    },
    checkDownloadJob(jobId) {
      farmService.checkDownloadJob(jobId).then(({ progress, total, result }) => {
        this.downloadStatus = { progress, total, result };
        if (progress === total) {
          this.progress = `下载完成(${progress}/${total})，正在压缩打包...`;
        } else {
          this.progress = `正在下载 ${progress}/${total}`;
        }
        if (result) {
          this.progress = '';
          window.open(`/api/v3/temp/${result}`);
        } else {
          setTimeout(() => {
            this.checkDownloadJob(jobId);
          }, 5 * 1000);
        }
      });
    },
    openAnalyseTool(dateData) {
      const routeData = this.$router.resolve({
        name: 'analyze-tool',
        query: {
          time: dateData,
          turbineName: this.checkedTurbine.turbineName,
          type: this.selectedDataType.type.toLowerCase(),
          farmPinyin: this.checkedTurbine.pinyinCode,
        },
      });
      window.open(`//${window.location.host}/micro-apps/tool${routeData.href}`, '_blank');
    },
    // 下载
    downloadFile(coskey) {
      service.downloadFile(coskey);
    },

    setDataType(item) {
      this.selectedDataType = item;
    },
    // 获取月份
    getMonth(start, end) {
      const arr = [];
      const tmp = moment(start).clone().add(-1, 'month');
      if (tmp.isAfter(start, 'd')) {
        arr.push(tmp.format('YYYY-MM'));
      }
      while (tmp.isBefore(end)) {
        tmp.add(1, 'month');
        arr.push(tmp.format('YYYY-MM'));
      }
      return arr;
    },
    getDays(month) {
      const arr = [];
      const start = moment(month).startOf('month').format('YYYY-MM-DD');
      const end = moment(month).endOf('month').format('YYYY-MM-DD');
      const tmp = moment(start).clone().add(-1, 'days');
      if (tmp.isAfter(start, 'd')) {
        arr.push(tmp.format('YYYY-MM-DD'));
      }
      while (tmp.isBefore(end)) {
        tmp.add(1, 'days');
        arr.push(tmp.format('YYYY-MM-DD'));
      }
      return arr;
    },
    donwloadSecData() {
      this.$router.push({
        name: 'secDownload',
        params: { pinyinCode: this.checkedTurbine.pinyinCode },
      });
    },
    openEdilt() {
      this.showMarkdown = true;
    },
  },
};
</script>

<template>
  <div style="height: 100%">
    <div class="farmInfo-top widget-content-area">
      <div>
        <el-radio v-model="showBasics" label="1"> 基础信息 </el-radio>
        <el-radio v-model="showBasics" label="2"> 整体参数 </el-radio>
        <el-button class="btn" type="success" size="medium" @click="openEdilt">
          更多信息
        </el-button>
      </div>
      <el-row v-if="showBasics === '1'">
        <el-col :span="8">
          <p>
            <label>样机名称：</label><span>{{ checkedTurbine.protoName }}</span>
          </p>
          <p>
            <label>机位号：</label><span>{{ checkedTurbine.turbineName }}</span>
          </p>
          <p>
            <label>风场名称：</label><span>{{ checkedTurbine.farmName }}</span>
          </p>
          <p>
            <label>样机机型：</label><span>{{ checkedTurbine.turbineType }}</span>
          </p>
        </el-col>
        <el-col :span="6">
          <p>
            <label>样机开始时间：</label><span>{{ checkedTurbine.startTime }}</span>
          </p>
          <p>
            <label>样机结束时间：</label><span>{{ checkedTurbine.endTime }}</span>
          </p>
          <p>
            <label>样机阶段：</label><span>{{ checkedTurbine.status }}</span>
          </p>
          <p>
            <label>省份：</label><span>{{ checkedTurbine.province }}</span>
          </p>
        </el-col>
        <el-col :span="10">
          <p>
            <label>发电机：</label><span>{{ checkedTurbine.alternator }}</span>
          </p>
          <p>
            <label>齿轮箱：</label><span>{{ checkedTurbine.gearBox }}</span>
          </p>
          <p>
            <label>变流器：</label><span>{{ checkedTurbine.converter }}</span>
          </p>
          <p>
            <label> 变桨：</label><span>{{ checkedTurbine.pitch }}</span>
          </p>
          <p>
            <label>偏航：</label><span>{{ checkedTurbine.yaw }}</span>
          </p>
        </el-col>
      </el-row>
      <el-row v-if="showBasics === '2'">
        <el-col :span="6">
          <p>
            <label>额定功率：</label><span>{{ checkedTurbine.ratedPower }}</span>
          </p>
          <p>
            <label>额定视在功率：</label><span>{{ checkedTurbine.ratedApparentPower }}</span>
          </p>
          <p>
            <label>额定电流：</label><span>{{ checkedTurbine.ratedCurrent }}</span>
          </p>
          <p>
            <label>额定电压：</label><span>{{ checkedTurbine.ratedVoltage }}</span>
          </p>
          <p>
            <label>轮毂中心距地面高度：</label><span>{{ checkedTurbine.hubCenterHeight }}</span>
          </p>
        </el-col>
        <el-col :span="6">
          <p>
            <label>额定风速：</label><span>{{ checkedTurbine.ratedWindSpeed }}</span>
          </p>
          <p>
            <label>切入风速：</label><span>{{ checkedTurbine.cutInWind }}</span>
          </p>
          <p>
            <label>切出风速：</label><span>{{ checkedTurbine.cutOutWind }}</span>
          </p>
          <p>
            <label>最大感性无功容量：</label><span>{{ checkedTurbine.maxPerceptualCapacity }}</span>
          </p>
          <p>
            <label>最大容性无功容量：</label
            ><span>{{ checkedTurbine.maxCompatibilityCapacity }}</span>
          </p>
        </el-col>
        <el-col :span="6">
          <p>
            <label>高压侧短路容量：</label
            ><span>{{ checkedTurbine.highVoltageShortCircuitCapacity }}</span>
          </p>
          <p>
            <label>塔筒高度：</label><span>{{ checkedTurbine.hubHeight }}</span>
          </p>
          <p>
            <label>叶片长度：</label><span>{{ checkedTurbine.bladeLength }}</span>
          </p>
          <p>
            <label>额定转速：</label><span>{{ checkedTurbine.ratedSpeed }}</span>
          </p>
        </el-col>
        <el-col :span="6">
          <p>
            <label>塔筒频率-固有频率：</label><span>{{ checkedTurbine.hubRate }}</span>
          </p>
          <p>
            <label>叶片频率-挥舞一阶：</label><span>{{ checkedTurbine.waveOneStep }}</span>
          </p>
          <p>
            <label>叶片频率-摆阵一阶：</label><span>{{ checkedTurbine.shimmyOneStep }}</span>
          </p>
        </el-col>
      </el-row>
    </div>
    <div class="box-bottom">
      <div class="explorer-container">
        <div class="widget-content widget-content-area explorer-content">
          <div class="status-tag-row">
            <div style="display: flex">
              <div class="status-tag-wrapper">
                <div class="status-tag full" />
                数据完整
              </div>
              <div class="status-tag-wrapper">
                <div class="status-tag incomplete" />
                数据缺失
              </div>
              <div class="status-tag-wrapper">
                <div class="status-tag empty" />
                无数据
              </div>
            </div>
          </div>
          <div class="table-responsive table-sticky-wrapper">
            <table class="table table-bordered dataTable">
              <thead>
                <tr>
                  <th class="text-center" style="width: 130px !important">时间</th>
                  <th v-for="d in 31" :key="d" class="text-center">
                    {{ d }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="Object.keys(tableData).length > 0">
                <tr v-for="(item, date, index) of tableData" :key="index + ''">
                  <td class="text-center pointer">
                    {{ date }}
                  </td>
                  <td
                    v-for="(dateData, j) in getDays(date)"
                    :key="'date-' + j"
                    class="text-center"
                    @click="openAnalyseTool(dateData)"
                  >
                    <!-- {{ j + 1 }} -->
                    <div
                      class="color-block"
                      :class="computeTurbineStatus(item[dateData])"
                      variant="primary"
                    >
                      <span v-if="dateData">{{ j + 1 }}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="32">
                    <el-empty />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="widget-content widget-content-area filter-status-form">
          <div class="download-box">
            <div class="form-group">
              <div class="filter-list">
                <a
                  v-for="(item, index) in dataTypes"
                  :key="index + ''"
                  :class="selectedDataType && selectedDataType.type === item.type ? 'active' : ''"
                  @click="setDataType(item)"
                >
                  {{ item.name }}
                </a>
              </div>
            </div>
            <div class="form-group">
              <el-date-picker
                v-model="dateRange"
                :picker-options="datePickerOptions"
                value-format="yyyy-MM-dd"
                type="daterange"
                size="medium"
                :clearable="false"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                range-separator="至"
              />
            </div>
            <div v-if="!progress" class="form-group">
              <el-button class="btn-block" type="primary" size="medium" @click="downloadData">
                <i class="fas fa-download" />
                下载 {{ peekResult && `(${peekResult.count})` }}
              </el-button>
            </div>

            <div v-if="progress" class="browser-list">
              <div class="w-browser-details">
                <div class="w-browser-info">
                  <p>{{ progress }}</p>
                </div>
                <el-progress
                  class="progress-indicator"
                  :text-inside="true"
                  :stroke-width="18"
                  :percentage="percentage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="详细信息"
      :visible.sync="showMarkdown"
      width="60%"
      top="60px"
      class="abow_dialog"
    >
      <Viewer
        v-show="checkedTurbine.content"
        ref="toastuiViewer"
        style="max-height: calca(100% - 100px)"
        :initial-value="checkedTurbine.content"
      />
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
@import "../explorer/explorer.scss";

$health: $success; // 数据完整
$warning: $warning; // 数据缺失
$error: #e7515a; // 数据报警
$empty: transparent; // 无数据

.farmInfo-top.widget-content-area {
  padding: 3px 20px;

  div > p {
    line-height: 28px;
    font-size: 13px;

    label {
      font-size: 13px;
      margin-bottom: 0;
      color: var(--secondary);
    }
  }

  .btn {
    float: right;
  }
}

.empty {
  background: $empty;
}

// 故障状态 健康、报警、故障
.health {
  background: rgba(0, 150, 136, 0.34902);
}

.warning {
  background: $warning;
}

.error {
  background: rgba(231, 81, 90, 0.439216);
}

// 数据状态 完整、缺失
.full {
  background: $health;
}

.incomplete {
  background: $warning;
}

.box-bottom {
  height: calc(100% - 185px);
  // margin:  0 10px;
  padding-bottom: 10px;

  .viewer-box {
    height: 100%;
    overflow-y: auto;
  }
}

.explorer-container {
  height: 100%;
}

.file-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px;
  margin-bottom: 4px;

  &:hover {
    background: #dedede;
  }

  span {
    color: $font-color;
  }

  .action-group {
    display: flex;
    align-items: center;
    margin-left: 20px;

    .action-link {
      margin-left: 8px;
      color: $primary;
      font-size: 12px;
      cursor: pointer;
    }
  }
}

.el-checkbox {
  padding-left: 20px;
}

::v-deep(.el-select__tags) {
  max-height: 120px;
  overflow-y: auto;
}

.farmInfo-top {
  height: 175px;
  line-height: 32px;
  margin: 5px 0px 15px;
}

.explorer-container .explorer-content .table-sticky-wrapper {
  height: auto;
}

::v-deep(.el-dialog) {
  height: 80vh;
}

::v-deep(.el-dialog__body) {
  padding: 0px 30px;
  height: 90%;
  overflow-y: auto;
}

.progress-indicator {
}
</style>
