import moment from 'moment';
import _ from 'lodash';
import appConfig from '@/app.config.json';
import { uikit } from '@/utils';
import { farmService as service, otherService } from '@/service/index';
import { queryParamsUtils } from '@sany/web-utils';
import sharedActions from '@/shared/actions';
import ExplorerTableView from './explorer-table/index.vue';

export default {
  name: 'TurbineExplorer',
  page: {
    title: '数据下载-风机',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    ExplorerTableView,
  },
  props: ['farmPinyin'],
  data: () => {
    return {
      isLoading: true,
      turbineList: [],
      turbineStatusList: [],
      hideEmptyTurbine: true,
      currentDate: moment().format('YYYY-MM'),
      selectedDataCategory: 2,
      selectedDataType: {},
      selectedTurbines: [],
      selectedTag: {},
      dateRangeStr: [],
      peekResult: null,
      downloadStatus: null,
      progress: '',
      files: [],
      selectedTurbinesAllIschecked: false,
      farmTurbineList: [],
    };
  },
  computed: {
    pinyinCode() {
      const pinyinCode = this.farmPinyin ? this.farmPinyin : this.$route.params.pinyinCode;
      return pinyinCode;
    },
    farmDetail() {
      const farms = this.$store.state.farm.list.filter((ent) => ent.pinyinCode === this.pinyinCode);
      return farms.length > 0 ? farms[0] : null;
    },
    dataTypes() {
      return this.$store.state.farm.dataTypes;
    },
    tags() {
      return this.$store.state.farm.tags;
    },
    currentMonthStr() {
      return moment(this.currentDate).format('YYYY-MM');
    },
    daysOfMonth() {
      if (this.currentDate) {
        const monthDate = new Date(this.currentDate);
        const days = new Date(monthDate.getFullYear(), monthDate.getMonth() + 1, 0).getDate();
        return days;
      }
      return '';
    },

    // 搜索选项
    downloadConditions() {
      return {
        farmPinyinCode: this.pinyinCode,
        currentDate: this.currentMonthStr,
        dataType: this.selectedDataType,
        turbines: this.selectedTurbines,
        dateRangeStr: this.dateRangeStr,
      };
    },
    downloadPrecent() {
      const { progress, total } = this.downloadStatus;
      return Math.floor((progress / total) * 100);
    },
  },
  watch: {
    pinyinCode() {
      this.computeMonthDuration();
      this.doSearch();
    },
    hideEmptyTurbine(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.onInitTurbineList();
      }
    },
  },

  async mounted() {
    this.selectedDataType = this.dataTypes[0];
    await this.getTurbines(this.pinyinCode);
    this.initData();
  },
  methods: {
    async initData() {
      const params = await queryParamsUtils.getParams();
      if (params.type) {
        const filterType = this.dataTypes.find((item) => item.name === params.type);
        this.selectedDataType = filterType || this.dataTypes[0];
      } else {
        this.selectedDataType = this.dataTypes[0];
      }
      const { monthStart, monthEnd } = this.computeMonthDuration();
      this.dateRangeStr = params.dateRangeStr?.length ? params.dateRangeStr : [monthStart, monthEnd];
      if (params.turbines) {
        this.selectedTurbines = params.turbines;
      }
      this.doSearch();
      this.peekDownloadResult();
    },
    async getTurbines(pinyinCode) {
      const data = await service.getTurbines(pinyinCode);
      this.farmTurbineList = data || [];
    },
    computeMonthDuration() {
      this.currentDate = this.$route.params.currentDate || moment().format('YYYY-MM');
      const month = moment(this.currentDate).format('YYYY-MM');
      const monthStart = moment(`${month}-01`, 'YYYY-MM-DD').format('YYYY-MM-DD');
      const monthEnd = moment(new Date()).format('YYYY-MM-DD');
      return { monthStart, monthEnd };
    },

    changeMonth(dateStr) {
      this.currentDate = dateStr;
      this.doSearch(dateStr);
    },
    setDataType(type) {
      this.selectedDataType = type;
      this.peekDownloadResult();
    },

    // 搜索
    doSearch(month) {
      this.isLoading = true;
      service.searchTurbineStatusList(this.pinyinCode, month || this.currentMonthStr).then((data) => {
        this.turbineStatusList = data;
        this.onInitTurbineList();
        this.isLoading = false;
      });
    },

    // 过滤掉没有数据的风机
    onInitTurbineList() {
      if (this.hideEmptyTurbine) {
        const turbines = [];
        // eslint-disable-next-line array-callback-return
        this.farmTurbineList.map((t) => {
          const turbineStatus = this.turbineStatusList.filter((ent) => ent.turbine.turbineId === t.turbineId)[0];
          if (turbineStatus.dates.filter((day) => day !== null).length > 0) {
            turbines.push(t);
          }
        });
        this.turbineList = turbines;
      } else {
        this.turbineList = this.farmTurbineList;
      }
    },
    showTurbineRow(turbineStatusData) {
      if (!this.hideEmptyTurbine) {
        return true;
      }
      return turbineStatusData.dates.filter((day) => day !== null).length > 0;
    },

    downloadOptions() {
      let from = '';
      let to = '';
      if (this.dateRangeStr) {
        from = moment(this.dateRangeStr[0]).format('YYYY-MM-DD');
        to = moment(this.dateRangeStr[this.dateRangeStr.length - 1]).format('YYYY-MM-DD');
      }
      const turbines = this.selectedTurbines.length ? [...this.selectedTurbines, 'all'] : this.selectedTurbines;
      const options = {
        farms: [this.pinyinCode],
        turbines,
        types: [],
        from,
        to,
      };
      if (this.selectedDataType.name !== 'ALL') {
        options.types = [this.selectedDataType.name];
      } else {
        options.types = this.dataTypes.map((item) => item.name).filter((item) => item !== 'ALL');
      }
      return options;
    },
    setRouterParams() {
      return {
        type: this.selectedDataType.name,
        dateRangeStr: this.dateRangeStr,
        turbines: this.selectedTurbines,
      };
    },
    peekDownloadResult() {
      queryParamsUtils.setParams(this.setRouterParams(), 'normal');
      service.peekCosFile(this.downloadOptions()).then((result) => {
        this.peekResult = result;
      });
    },
    downloadData() {
      if (this.peekResult && this.peekResult.count > 0) {
        service.downloadCosFile(this.downloadOptions()).then((res) => {
          const { count, jobId } = res;
          if (jobId) {
            this.progress = `正在下载 ${0 / count}`;
            this.checkDownloadJob(jobId);
          } else {
            uikit.error(res.message);
          }
        });
      } else {
        uikit.message('没有文件可以下载！');
      }
    },
    donwloadSecData() {
      this.$router.push({ name: 'secDownload', params: { pinyinCode: this.pinyinCode } });
    },
    checkDownloadJob(jobId) {
      service.checkDownloadJob(jobId).then(({
        progress, total, result, status,
      }) => {
        this.downloadStatus = {
          progress, total, result, status,
        };
        if (progress === total) {
          this.progress = `下载完成(${progress}/${total})，正在压缩打包...`;
        } else {
          this.progress = `正在下载 ${progress}/${total}`;
        }
        if (result) {
          this.progress = '';
          // window.open(`/api/v3/temp/${result}`);
          service.updatePackageFileStatus({
            jobId,
            url: result,
            status,
          });
          otherService.downLoadFile(result);
        } else {
          setTimeout(() => {
            this.checkDownloadJob(jobId);
          }, 5 * 1000);
        }
      });
    },

    onSearchFileList(turbine, day) {
    },

    // 下载
    downloadFile(coskey) {
      service.downloadFile(coskey);
    },
    // 分析
    onAnalyze(cosKey) {
      window.open(`/upload-analyze?key=${cosKey}`);
    },
    selectTurbinesChange(val) {
      if (val.length === this.turbineList.length) {
        this.selectedTurbinesAllIschecked = true;
      } else {
        this.selectedTurbinesAllIschecked = false;
      }
      this.peekDownloadResult();
    },
    selectAllTurbines() {
      this.selectedTurbines = [];
      if (this.selectedTurbinesAllIschecked) {
        // eslint-disable-next-line array-callback-return
        this.turbineList.map((item) => {
          this.selectedTurbines.push(item.turbineName);
        });
      } else {
        this.selectedTurbines = [];
      }
      this.peekDownloadResult();
    },
    changeDate() {
      this.peekDownloadResult();
    },
  },
};
