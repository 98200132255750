<script>
import moment from 'moment';
import { cmsService as service } from '@/service';
import { uikit } from '@/utils';
import _ from 'lodash';

function evil(fn) {
  const Fn = Function; // 一个变量指向Function，防止有些前端编译工具报错
  return new Fn(`return ${fn}`)();
}
export default {
  name: 'CmsHeader',
  props: ['cludesType', 'frequencyList', 'isDriveChain'],
  data() {
    return {
      farms: [],
      selectedFarm: {},
      selectedTurbine: {},
      dateRangeStr: '',
      selectedFrequency: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          },
        }],
      },
      selectedMeasurePoints: [],
      measurePoints: [],
    };
  },
  computed: {
    farmsMap() {
      return JSON.parse(JSON.stringify(this.$store.state.farm.map));
    },
    frequencyListData() {
      let allSelectPointsFilterRange = [];
      this.selectedMeasurePoints.forEach((point) => {
        allSelectPointsFilterRange = allSelectPointsFilterRange.concat(point.filter_range);
      });
      // 选中的测点采样频率对象去重
      const filterRangeObjs = _.uniqWith(allSelectPointsFilterRange, _.isEqual);
      const frequencise = [];
      filterRangeObjs?.forEach((filterRange) => {
        const pointFrequency = evil(filterRange.frequency);
        pointFrequency.forEach((frequency) => {
          const obj = {
            filterRange: filterRange.filter_range,
            samplingFrequencyType: filterRange.sampling_frequency_type === 'speed' ? '速度' : '加速度',
          };
          obj.frequency = frequency;
          frequencise.push(obj);
        });
      });
      return _.uniqWith(frequencise, _.isEqual);
    },
  },
  watch: {
    frequencyListData(newVal) {
      this.selectedFrequency = newVal[0];
      this.sendSelectOptions();
    },
    selectedFarm(newVal, oldVal) {
      this.selectedTurbine = newVal.turbines[0].turbineName;
      this.getPoints(this.selectedFarm.pinyinCode, this.selectedTurbine);
    },
    selectedTurbine(newVal, oldVal) {
      if (newVal) {
        this.getPoints(this.selectedFarm.pinyinCode, newVal);
      }
    },
  },
  mounted() {
    this.fetchCmsFarms();
    this.initDateRangeStr();
  },
  methods: {
    initDateRangeStr() {
      const dateFrom = moment().subtract(30, 'd').format('YYYY-MM-DD');
      const dateTo = moment().format('YYYY-MM-DD');
      this.dateRangeStr = [dateFrom, dateTo];
    },
    sendSelectOptions() {
      if (!this.selectedFrequency) {
        uikit.error('请选择采样频率');
        return;
      }
      if (this.selectedMeasurePoints.length === 0) {
        uikit.error('请选择测点');
        return;
      }
      const options = {
        farmCode: this.selectedFarm.pinyinCode,
        turbineName: this.selectedTurbine,
        from: moment(this.dateRangeStr[0]).format('YYYY-MM-DD'),
        to: moment(this.dateRangeStr[1]).format('YYYY-MM-DD 23:59:59'),
        pointName: this.selectedMeasurePoints,
        frequency: this.selectedFrequency ? [this.selectedFrequency.frequency] : [],
        filterRange: this.selectedFrequency ? this.selectedFrequency.filterRange : '',
      };
      this.$store.commit('SET_TOOL_HEADER_OPTIONS', options);
      // this.$emit('getSelectOptions', options)
    },
    getPoints(pinyinCode, turbineName) {
      service.fetchPoints(pinyinCode, turbineName).then((res) => {
        this.measurePoints = res.data;
        if (this.measurePoints.length) {
          this.selectedMeasurePoints = [this.measurePoints[0]];
        }
      });
    },
    fetchCmsFarms() {
      service.fetchCmsFarms().then((results) => {
        this.farms = results.map((result) => this.farmsMap[result]).filter((item) => item !== undefined);
        this.selectedFarm = this.farms[0];
        this.selectedTurbine = this.selectedFarm.turbines[0]?.turbineName;
      });
    },
    goNewHref() {
      const routeData = this.$router.resolve({
        name: 'analyze-tool',
        query: {
          type: 'cms',
          turbineName: this.selectedTurbine,
          farmPinyin: this.selectedFarm?.pinyinCode,
        },
      });
      window.open(`//${window.location.host}/micro-apps/tool${routeData.href}`, '_blank');
    },
  },
};
</script>

<template>
  <div class="filter-form">
    <!--模糊搜索-->
    <div class="search-wrap-container">
      <div class="flex-item">
        <el-select v-model="selectedFarm" size="small" style="width: 200px;" class="sany-selector" filterable placeholder="请选择风场" value-key="pinyinCode">
          <el-option v-for="farm in farms" :key="farm.pinyinCode" :label="farm.farmName" :value="farm" />
        </el-select>
      </div>
      <div class="flex-item">
        <el-select v-model="selectedTurbine" size="small" style="width: 200px;" class="sany-selector" filterable placeholder="请选择风机" value-key="turbineId">
          <el-option v-for="turbine in selectedFarm.turbines" :key="turbine.turbineName" :label="turbine.turbineName" :value="turbine.turbineName" />
        </el-select>
      </div>
      <div class="flex-item">
        <el-date-picker
          v-model="dateRangeStr"
          size="small"
          style="width: 350px;"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        />
      </div>
      <div class="flex-item">
        <el-select v-model="selectedMeasurePoints" style="width: 200px;" size="small" multiple class="sany-selector" value-key="point_name" placeholder="测点选择（可多选）">
          <el-option v-for="(p, index) in measurePoints" :key="index + ''" :label="p.point_name" :value="p" />
        </el-select>
      </div>
      <div class="flex-item">
        <el-select v-model="selectedFrequency" style="width: 200px;" size="small" class="sany-selector" placeholder="采样频率选择（单选）" value-key="frequency">
          <el-option
            v-for="(option, index) in frequencyListData"
            :key="`frequency_${index}`"
            :value="option"
            :label="`${ option.samplingFrequencyType }(${ option.frequency })`"
          />
        </el-select>
      </div>
      <el-button type="primary" size="small" @click="sendSelectOptions">
        确定
      </el-button>
      <el-button type="warning" size="small" @click="goNewHref">
        新版体验
      </el-button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .search-wrap-container {
    display: flex;
    flex-wrap: wrap;
    .flex-item {
      margin-right: 20px;
    }
  }
</style>
