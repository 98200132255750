<script>
import { SearchIcon } from 'vue-feather-icons';
import HomeMap from './HomeMap.vue';
import FarmIndex from './FarmIndex';

export default {
  // TODO: 根配置
  name: 'Home',
  components: {
    SearchIcon,
    HomeMap,
    FarmIndex,
  },
  data() {
    return {
      editableTabsValue: 'home',
      editableTabs: [],
      tabIndex: 2,
      keyword: '',
      farmsLists: [],
    };
  },
  computed: {
    farms() {
      const arr = this.$store.state.farm.list;
      arr.forEach((f) => {
        if (f.turbines) {
          f.turbinesTypeCnt = Object.keys(this.getArrayRepeatsAttr(f.turbines, 'typeName')).length;
        }
      });
      return arr;
    },
  },
  watch: {
    keyword(n, o) {
      this.doSearch(n);
    },
  },
  mounted() {
    this.farmsLists = JSON.parse(JSON.stringify(this.farms));
  },
  methods: {
    getArrayRepeatsAttr(arr, attr) {
      const arrData = arr;
      const obj = {};
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < arrData.length; i++) {
        const item = arrData[i][attr]; // a为计算的属性,可换成b,c
        if (item) {
          obj[item] = (obj[item] + 1) || 1;
        }
      }
      return obj;
    },
    doSearch(value) {
      this.farmsLists = this.farms.filter((i) => {
        const searchField = { province: i.province, farmName: i.farmName };
        return Object.keys(searchField).some((key) => String(i[key]).toUpperCase().indexOf(value) > -1 || String(i[key]).toLowerCase().indexOf(value) > -1);
      });
    },
    farmLihandleClick(item, index) {
      const tabArr = this.editableTabs.filter((tab) => tab.name === item.pinyinCode);
      if (tabArr.length === 0) {
        const obj = {
          title: item.farmName,
          name: item.pinyinCode,
        };
        this.editableTabs.push(obj);
      }
      this.editableTabsValue = item.pinyinCode;
    },
    removeTab(targetName) {
      const tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            const nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }
      if (tabs.length > 1) {
        this.editableTabsValue = activeName;
        this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
      } else {
        this.editableTabs = [];
        this.editableTabsValue = 'home';
      }
    },
    /* 活动标签切换时触发 */
    // eslint-disable-next-line consistent-return
    beforeLeave(currentName, oldName) {
      /* 活动标签切换时触发 */
      if (oldName !== undefined) {
        if (currentName === 'add') {
          this.addTab();
          return false;
        }
        this.currentIndex = currentName;
      } else {
        this.editableTabsValue = 'home';
      }
    },
    mapHandleClick(data) {
      this.keyword = data;
    },
  },
};
</script>

<template>
  <div class="home-container">
    <div class="header">
      <el-tabs v-model="editableTabsValue" type="card" :before-leave="beforeLeave" @tab-remove="removeTab">
        <el-tab-pane
          key="home"
          label="首页"
          name="home"
        />
        <el-tab-pane
          v-for="(item) in editableTabs"
          :key="item.name"
          :label="item.title"
          :name="item.name"
          closable
        />
      </el-tabs>
    </div>
    <div class="contain">
      <div class="contain-left">
        <HomeMap v-show="editableTabsValue === 'home'" :farms="farms" @mapHandleClick="mapHandleClick" />
        <FarmIndex v-if="editableTabsValue !== 'home'" :pinyin-code="editableTabsValue" />
      </div>
      <div class="contain-right">
        <div class="input-group">
          <input
            v-model="keyword"
            type="text"
            class="form-control"
            placeholder="风场名、省份"
            style="margin-left: 2px"
            @keyup.enter="doSearch(keyword)"
          >
          <div
            class="input-group-append"
            @click.prevent="doSearch(keyword)"
          >
            <span class="input-group-text pointer">
              <SearchIcon size="1.5x" />
            </span>
          </div>
        </div>
        <ul>
          <li v-for="(item, index) in farmsLists" :key="index + ''" class="farm-li" :class="{ active: isActive === index }" @click="farmLihandleClick(item, index)">
            <div>
              <p class="title">
                {{ item.farmName }}
              </p>
              <span>机型：{{ item.turbinesTypeCnt }}</span>
            </div>
            <div>
              <p class="sub-title">
                {{ item.pinyinCode }}
              </p>
              <span>装机数：{{ item.turbines.length }}台</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped lang='scss'>
.home-container{
  width: 100%;
  height: 100vh;
  background-color: #fff;
  ::v-deep .el-tabs__header{
    margin: 0;
    border: 0 none;
        box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07)
  }
  .form-control {
    height: calc(1.2em + 1.2rem + 2px)!important;
  }
  .contain{
    height: calc(100% - 51px);
    width: 100%;
    position: relative;
  }
  .contain-left{
    width: 100%;
    height: 100%;
    padding-right: 270px;
  }
  .contain-right{
    position: absolute;
    right: 10px;
    top: 0;
    width: 260px;
    height: 100%;
    background: transparent;
    padding-bottom: 10px;
    ul{
      height: calc(100% - 38px);
      list-style: none;
      overflow: auto;
      font-size: 14px;
      padding: 0;
      margin-top: 10px;
    }
    .farm-li{
      cursor: pointer;
      padding:5px 10px;
      background: #ececec;
      margin: 0 3px 7px;
      div{
        height: 26px;
        line-height: 26px;
      }
      p{
        float: left;
      }
      .title{
        font-weight: bolder;
        color: #e0aa56;
      }
      .sub-title{
        color: #ad9d25;
      }
      span{
        float: right;
      }
    }
    li:hover{
      background: #122239;
      color: #5abd49;
    }
    li.active{
      // background: salmon;
    }
  }
}
</style>
