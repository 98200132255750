<script>

import appConfig from '@/app.config';

import TurbineExplorerView from '../explorer/turbine/index.vue';

export default {
  name: 'TurbineExplorer',
  page: {
    title: '风场-数据下载',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    TurbineExplorerView,
  },
  props: ['farmCode'],

};
</script>

<template>
  <div>
    <TurbineExplorerView :farm-pinyin="farmCode" />
  </div>
</template>
