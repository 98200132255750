<script>
import mapData from '@/base/china1.js';
import echarts from 'echarts';
import { reportService as service } from '@/service';
import moment from 'moment';
import _ from 'lodash';

export default {
  // TODO: 根配置
  name: 'HomeMap',
  components: {
  },
  props: ['farms'],
  data() {
    return {
      turbinesCount: 0,
      farmsLastMonthlyEnergy: 0,
      defaultZoom: null,
      turbineTypesArr: [],
      turbineTypesCountData: {},
      turbineTypesDataArr: [],
      platformYwsList: [],
      platformYwsCountData: {},
      platformYwsDataArr: [],
      // farmsData:[]
    };
  },
  computed: {
    farmsData() {
      const data = [];
      this.farms.forEach((f) => {
        if (f.latitude && f.longitude) {
          const obj = {
            name: f.farmName,
            value: [f.longitude, f.latitude, 0],
            itemStyle: {
            // color按照自己的需求设置，
              color: '#ff9640',
            },
            symbolSize: 10,
          };
          data.push(obj);
        }
      });
      return data;
    },

  },
  mounted() {
    this.initMap();
    this.initData();
    this.initBar();
    this.getLastMonthlyEnergy();
    window.addEventListener('resize', () => {
      const myChartCity = echarts.init(document.getElementById('mapLeft'));
      myChartCity.resize();
    });
  },
  methods: {
    covertStopData() {
      const geoCoordMap = {
        新疆: [86.61, 40.79],
        西藏: [89.13, 30.66],
        黑龙江: [128.34, 47.05],
        吉林: [126.32, 43.38],
        辽宁: [123.42, 41.29],
        内蒙古: [112.17, 42.81],
        北京: [116.40, 40.40],
        宁夏: [106.27, 36.76],
        山西: [111.95, 37.65],
        河北: [115.21, 38.44],
        天津: [117.04, 39.52],
        青海: [97.07, 35.62],
        甘肃: [103.82, 36.05],
        山东: [118.01, 36.37],
        陕西: [108.94, 34.46],
        河南: [113.46, 34.25],
        安徽: [117.28, 31.86],
        江苏: [120.26, 32.54],
        上海: [121.46, 31.28],
        四川: [103.36, 30.65],
        湖北: [112.29, 30.98],
        浙江: [120.15, 29.28],
        重庆: [107.51, 29.63],
        湖南: [112.08, 27.79],
        江西: [115.89, 27.97],
        贵州: [106.91, 26.67],
        福建: [118.31, 26.07],
        云南: [101.71, 24.84],
        台湾: [121.01, 23.54],
        广西: [108.67, 23.68],
        广东: [113.98, 22.82],
        海南: [110.03, 19.33],
        澳门: [113.54, 22.19],
        香港: [114.17, 22.32],
      };
      const farmsProvinceCount = this.$parent.getArrayRepeatsAttr(this.farms, 'province');
      const farmsProvinceCountData = [];
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const p in farmsProvinceCount) {
        farmsProvinceCountData.push({
          name: p,
          value: [_.get(geoCoordMap, `${p}[0]`), _.get(geoCoordMap, `${p}[1]`), farmsProvinceCount[p]],
          itemStyle: {
            color: '#F9DE1C',
          },
          symbolSize: 20,
        });
      }
      return farmsProvinceCountData;
    },
    initMap() {
      mapData.features.forEach((feature) => {
        const provinceCnt = this.$parent.getArrayRepeatsAttr(this.farms, 'province');
        feature.properties.name += `:${provinceCnt[feature.properties.name] || 0}`;
      });
      echarts.registerMap('china', mapData);
      const myChartCity = echarts.init(document.getElementById('mapLeft'));
      const optionCity = {
        backgroundColor: '#f8f8f8',
        tooltip: {
          trigger: 'item',
          borderRadius: 0,
          backgroundColor: '#084186',
          formatter: '{b}',
        },
        geo: {
          map: 'china',
          zoom: 1.2,
          label: {
            normal: {
              textStyle: { color: '#92b1d8', fontSize: '12', fontFamily: '微软雅黑' },
              show: true, //  是否显示字体
            },
            //  滑过显示样式
            emphasis: {
              textStyle: { color: '#fff', fontSize: '14', fontFamily: '微软雅黑' },
              show: true,
            },
          },
          // left: '15%',
          roam: true,
          itemStyle: {
            normal: {
              areaColor: 'rgba(18 ,34 ,57, 0.85)',
              borderColor: '#111',
            },
            emphasis: {
              show: true,
              areaColor: '#8dbf42',
            },
          },
        },
        series: [
          {
            name: 'one',
            type: 'scatter',
            coordinateSystem: 'geo',
            // data: this.farmsData,
            data: this.covertStopData(),
            label: {
              normal: {
                // formatter: '{b}',
                formatter: '{@2}',
                position: 'inside',
                show: true,
              },
              emphasis: {
                show: true,
              },
            },
            // symbolSize: 10,
            showEffectOn: 'render',
            rippleEffect: {
              brushType: 'stroke',
            },
            hoverAnimation: true,
            z: 1,
          },
        ],
      };
      myChartCity.setOption(optionCity);
      myChartCity.on('georoam', () => {
        // eslint-disable-next-line no-underscore-dangle
        const _options = myChartCity.getOption();
        const zoom = _options.geo[0].zoom;
        if (zoom === this.defaultZoom) return;
        if (zoom >= 1) {
          _options.tooltip = {
            trigger: 'item',
            borderRadius: 0,
            backgroundColor: '#084186',
            formatter: (p) => {
              const a = p.name;
              const obj = this.farms.filter((f) => f.farmName === p.name)[0];
              const text = `${p.name}</br>`
                + `机型：<span style='color:#f0921e'>${obj.turbinesTypeCnt}</span></br>`
                + `装机台数：<span style='color:#f0921e'>${obj.installedNum}</span></br>`;
              return text;
            },
          };
          _options.series = [
            {
              data: this.farmsData,
              label: {
                normal: {
                  show: false,
                },
                emphasis: {
                  show: false,
                },
              },
            },
          ];
        } else if (zoom < 1) {
          _options.tooltip = {
            trigger: 'item',
            borderRadius: 0,
            backgroundColor: '#084186',
            formatter: '{b}',
          };
          _options.series = [
            {
              data: this.covertStopData(),
              label: {
                normal: {
                  // formatter: '{b}',
                  formatter: '{@2}',
                  position: 'inside',
                  show: true,
                },
                emphasis: {
                  show: true,
                },
              },
            },
          ];
        }
        this.defaultZoom = zoom;

        myChartCity.setOption(_options);
        // 更新数据
      });
      myChartCity.on('click', (params) => {
        this.$emit('mapHandleClick', params.name.split(':')[0]);
      });
    },
    initData() {
      const turbineTypes = [];
      this.farms.forEach((farm) => {
        farm.turbines.forEach((turbine) => {
          // eslint-disable-next-line no-plusplus
          this.turbinesCount++;
          if (this.platformYwsList.indexOf(turbine.platformYws) === -1) {
            this.platformYwsList.push(turbine.platformYws);
            this.platformYwsCountData[turbine.platformYws] = 1;
          } else {
            // eslint-disable-next-line no-plusplus
            this.platformYwsCountData[turbine.platformYws]++;
          }
        });
      });
      this.platformYwsList = Object.keys(this.platformYwsCountData).filter((platformYws) => platformYws !== 'null').sort((a, b) => this.platformYwsCountData[a] - this.platformYwsCountData[b]);
      this.platformYwsDataArr = this.platformYwsList.map((platformYws) => this.platformYwsCountData[platformYws]);
    },
    initBar() {
      const myChart = echarts.init(document.getElementById('pieBox'));
      /* 指定图表的配置项和数据 */
      const option = {
        textStyle: {
          fontSize: '12',
        },
        title: {
          left: '10%',
          text: '机型统计',
          /* subtext: '数据来自网络' */
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {
          show: false,
        },
        grid: { // 设置图表位置
          top: '5%',
          left: '3%',
          right: '8%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          splitLine: { // 去掉网格线
            show: false,
          },
          position: 'top', // X轴位置
          type: 'value',
          axisLabel: { // 坐标轴刻度标签的相关设置
            rotate: '45', // 坐标轴文字旋转角度
            show: true,
            textStyle: {
              align: 'right',
              fontSize: 12,
              color: '#666',
              fontWeight: 700,
            },
          },

          axisLine: {
            lineStyle: {
              color: '#666',
            },
          },
          axisTick: {
            lineStyle: {
              color: '#888',
            },
          },
        },
        yAxis: {
          type: 'category', // 轴的类型分两种 1.category（类别）2.value(值)
          // data: this.turbineTypesArr,
          data: this.platformYwsList,
          axisLabel: {
            show: true,
            interval: 0,
            textStyle: {
              align: 'right',
              fontWeight: 700,
              fontSize: 10, /* 文字大小 */
            },
          },
          axisLine: {
            lineStyle: {
              color: '#666',
            },
          },
          axisTick: {
            lineStyle: {
              color: '#888',
            },
          },
        },
        series: [
          {
            name: '装机数',
            type: 'bar',
            // data: this.turbineTypesDataArr, /* 请求回来的数据数组 */
            data: this.platformYwsDataArr,
            label: {
              normal: {
                show: true, // 显示数字
                position: 'right',
              },
            },
            itemStyle: {
              normal: {
                color(params) {
                  const colorList = [
                    '#C1232B', '#B5C334', '#FCCE10', '#E87C25', '#27727B',
                    '#FE8463', '#9BCA63', '#FAD860', '#F3A43B', '#60C0DD',
                    '#D7504B', '#C6E579', '#F4E001', '#F0805A', '#26C0C0',
                    '#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae',
                    '#749f83', '#ca8622',
                  ];
                  let index = 0;
                  if (params.dataIndex >= colorList.length) {
                    index = params.dataIndex - colorList.length;
                  } else {
                    index = params.dataIndex;
                  }
                  return colorList[index];
                },
                label: {
                  textStyle: {
                    fontSize: '12', // 柱状上的显示的文字
                  },
                },
                barBorderRadius: [0, 15, 15, 0],
              },
              emphasis: {
                barBorderRadius: [0, 15, 15, 0],
              },

            },

          },

        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    getLastMonthlyEnergy() {
      const lastMonth = moment(new Date()).subtract(1, 'months').format('YYYY-MM');
      service.searchMonthlyReport(lastMonth).then((results) => {
        const groupResults = this.groupBy(results, (item) => [item.farmName]);
        groupResults.forEach((result) => {
          this.farmsLastMonthlyEnergy += result[0].cyclePower;
        });
      });
    },
    groupBy(array, f) {
      const groups = {};
      array.forEach((o) => {
        const group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      return Object.keys(groups).map((group) => {
        return groups[group];
      });
    },
  },
};
</script>

<template>
  <div class="chart-box">
    <div class="mapTopLeft">
      <div class="mapTopLeft-left">
        <div class="white-border" />
        <p>风场：<em>{{ farms.length }}</em> 个</p>
        <p>风机：<em class="danger">{{ turbinesCount }}</em> 个</p>
      </div>
      <div class="mapTopLeft-right">
        <p>
          上月总发电量：<br><em class="full">{{ farmsLastMonthlyEnergy.toFixed(2) }}</em> 万kWh
        </p>
      </div>
    </div>
    <div id="mapLeft" ref="mapLeft" />
    <div id="pieBox" ref="pieBox" />
  </div>
</template>

<style scoped lang='scss'>
.chart-box{
  height: 100%;
  width: 100%;
  position: relative;
}
.mapTopLeft{
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 3;
  padding:10px 15px;
  font-size: 16px;
  font-weight: 600;
  background: rgba(18 ,34 ,57, 0.9);
  box-shadow: 0 0px 10px rgb(255 255 255);
  border-radius: 8px;
  // background: transparent;
  overflow: hidden;
  p{

  color: #fff;
  }
  em{
    color: #cf7e3d;
    font-size: 30px;
    font-style: normal;
    font-family: FZYaoti;
  }
  .danger{
    color: rgb(184, 6, 6);
  }
  .full{
    color: rgb(25, 145, 3);
  }
  .white-border{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    // background: linear-gradient(to left,#fff,#fff) left top no-repeat,
    // linear-gradient(to bottom,#fff,#fff) left top no-repeat,
    // linear-gradient(to left,#fff,#fff) right top no-repeat,
    // linear-gradient(to bottom,#fff,#fff) right top no-repeat,
    // linear-gradient(to left,#fff,#fff) left bottom no-repeat,
    // linear-gradient(to bottom,#fff,#fff) left bottom no-repeat,
    // linear-gradient(to left,#fff,#fff) right bottom no-repeat,
    // linear-gradient(to left,#fff,#fff) right bottom no-repeat;
   /*设置大小*/
    background-size: 0.15rem 0.6rem, 0.6rem 0.15rem, 0.15rem 0.6rem, 0.6rem 0.15rem;
  }
  .mapTopLeft-left{
    // float: left;
  }
  .mapTopLeft-right{
    margin-top: 10px;
    // float: right;
    // margin-left: 20px;
  }
}

#mapLeft{
  width: 100%;
  height: 100%;
}
#pieBox{
  width: 300px;
  height: 400px;
  position: absolute;
  left: 10px;
  bottom: 0px;
  border: 1px solid rgba(136, 136, 136, 0.6);
  background: rgba(255,255,255,0.5);
}
</style>
