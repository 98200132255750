<script>
import moment from 'moment';
import {
  AlignJustifyIcon,
  LayersIcon,
  DownloadIcon,
  CompassIcon,
  CalendarIcon,
  InfoIcon,
  FileMinusIcon,
} from 'vue-feather-icons';

import { protoTurbineService as service } from '@/service';
import menuList from './menuList.json';
import TurbineList from './TurbineList.vue';
import TurbineData from './TurbineData.vue';
import TurbineReport from './TurbineReport.vue';

export default {
  name: 'PrototypeTurbine',
  page: {
    title: '样机分析',
  },
  components: {
    TurbineList,
    TurbineData,
    TurbineReport,
    AlignJustifyIcon,
    LayersIcon,
    DownloadIcon,
    CompassIcon,
    CalendarIcon,
    InfoIcon,
    FileMinusIcon,
  },
  data() {
    return {
      menuList,
      checkedTurbine: {},
      contentId: -1,
      curMenu: {
        title: '样机列表',
        value: 'TurbineList',
      },
      turbineList: [],
      editableTabsValue: -1,
      editableTabs: [],
      tabIndex: 1,
      curIdx: 0,
      addTabObj: {},
      pinyinCode: 'WDSFCB',
      checkedProtoId: -1,
      isShowEdit: false,
      loading: false,
    };
  },
  computed: {
    farmsMap() {
      return this.$store.state.farm.map;
    },
  },
  watch: {
    checkedTurbine(newV, oldV) {
      this.checkTurbine(newV);
    },
  },
  mounted() {
    this.getTurbinesList();
  },
  methods: {
    getTurbinesList() {
      this.loading = true;
      service.fetchProroTurbineList().then((proroTurbines) => {
        this.loading = false;
        const datas = proroTurbines.map((proroTurbine) => {
          proroTurbine.ownerTurbineName = this.farmsMap[proroTurbine.pinyinCode]?.turbines?.find(
            (turbine) => turbine.turbineName === proroTurbine.turbineName,
          )?.ownerTurbineName;
          proroTurbine.startTime = proroTurbine.startTime
            ? moment(proroTurbine.startTime).format('YYYY-MM-DD')
            : '';
          proroTurbine.endTime = proroTurbine.endTime
            ? moment(proroTurbine.endTime).format('YYYY-MM-DD')
            : '';
          return proroTurbine;
        });
        this.turbineList = datas;
        const turbineObj = this.turbineList[0];
        this.checkedTurbine = turbineObj;
        this.checkedProtoId = turbineObj.id;
        this.loading = false;
      });
    },
    // 点击侧边栏
    menuHandleClick(item) {
      if (item.value !== 'DigitalTwin') {
        this.curMenu = item;
        this.isShowEdit = false;
      } else {
        // window.open('http://10.255.10.120:3000/#/model', '_blank');
      }
    },
    /* 活动标签切换时触发 */
    beforeLeave(currentName, oldName) {
      /* 活动标签切换时触发 */
      if (oldName !== undefined) {
        this.currentIndex = currentName;
        const turbineObj = this.turbineList.filter((t) => t.id === currentName)[0];
        this.checkedTurbine = turbineObj;
      } else {
        this.editableTabsValue = -1;
        this.checkedTurbine = {};
        this.curMenu = {
          title: '样机列表',
          value: 'TurbineList',
        };
      }
    },
    removeTab(targetName) {
      const tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            const nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }
      if (tabs.length > 1) {
        this.editableTabsValue = activeName;
        const turbineObj = this.turbineList.filter((t) => t.id === activeName)[0];
        this.checkedTurbine = turbineObj;
        this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
      } else {
        this.editableTabs = [];
        this.editableTabsValue = -1;
        this.curMenu = {
          title: '样机列表',
          value: 'TurbineList',
        };
      }
    },
    checkTurbine(item) {
      this.checkedProtoId = item.id;
      const turbineObj = this.turbineList.filter((t) => t.id === item.id)[0];
      this.checkedTurbine = turbineObj;
      const tabArr = this.editableTabs.filter((tab) => tab.name === item.id);
      if (tabArr.length === 0) {
        const obj = {
          title: item.protoName,
          name: item.id,
        };
        this.editableTabs.push(obj);
      }
      this.editableTabsValue = item.id;
    },
  },
};
</script>

<template>
  <div class="home-content">
    <div class="home-left-menu">
      <ul class="unstyled-list">
        <li
          v-for="(item, index) in menuList"
          :key="index + ''"
          :class="{ active: curMenu.value === item.value }"
          @click="menuHandleClick(item)"
        >
          <el-tooltip class="item" effect="dark" :content="item.title" placement="right">
            <Component :is="item.icon" />
          </el-tooltip>
        </li>
      </ul>
    </div>
    <div class="home-right-content">
      <div class="sub-header">
        <div
          v-if="curMenu.value === 'TurbineList'"
          class="header-title"
          @click="isShowEdit = false"
        >
          样机列表
        </div>
        <el-tabs
          v-else
          v-model="editableTabsValue"
          type="card"
          :before-leave="beforeLeave"
          @tab-remove="removeTab"
        >
          <el-tab-pane
            v-for="item in editableTabs"
            :key="item.name"
            :label="item.title"
            :name="item.name"
            closable
          />
        </el-tabs>
      </div>
      <div class="content-box">
        <div v-loading="loading" class="component-box">
          <PrototypeDetail v-if="curMenu.value === 'TurbineList' && isShowEdit" />
          <Component
            :is="curMenu.value"
            v-else
            :turbine-list="turbineList"
            :checked-turbine="checkedTurbine"
            @onTurbineChange="(e) => (this.checkedTurbine = e)"
          />
        </div>
        <div v-if="curMenu.value !== 'TurbineList'" class="turbines-box widget-content-area">
          <ul class="unstyled-list">
            <li
              v-for="(item, index) in turbineList"
              :key="index + ''"
              class="turbine-li"
              :class="{ cur: checkedProtoId === item.id }"
              @click="checkTurbine(item)"
            >
              <p>{{ item.protoName }}</p>
              <p>
                <span>{{ item.farmName }}</span>
                <span style="float: right">{{ item.turbineName }}</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.yzm-box {
  display: inline-block;
  width: 250px;
}
.yzm-box input {
  width: 175px;
  margin-right: 5px;
}
.yzm-box img {
  width: 70px;
}
.home-content {
  height: 100vh;
  width: 100%;
  position: relative;
  .home-left-menu {
    width: 60px;
    height: 100%;
    background: #060818;
    position: absolute;
    top: 0px;
    li {
      padding: 10px 0 10px 15px;
      margin-bottom: 7px;
      list-style: none;
      cursor: pointer;
    }
    li.active {
      background: rgba(59, 63, 92, 0.35);
    }
  }
  .home-right-content {
    height: 100%;
    width: 100%;
    padding-left: 60px;
    overflow: hidden;
    .header-title {
      color: #555;
      height: 50px;
      line-height: 50px;
      padding-left: 20px;
      background: #fff;
      cursor: pointer;
      box-shadow: 0 4px 6px 0 rgb(85 85 85 / 8%), 0 1px 20px 0 rgb(0 0 0 / 7%),
        0px 1px 11px 0px rgb(0 0 0 / 7%);
    }
    .el-tabs,
    .el-tab-pane {
      height: 100%;
    }
    ::v-deep(.el-tabs__header) {
      height: 50px;
      line-height: 50px;
      border-bottom: 0 none;
      box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07),
        0px 1px 11px 0px rgba(0, 0, 0, 0.07);
      margin-bottom: 0px;
    }
    ::v-deep(.el-tabs__content) {
      padding: 0;
      height: 0;
    }
    .content-box {
      // margin: 15px;
      display: flex;
      height: calc(100% - 50px);
      .component-box {
        flex: 1;
        height: 100%;
        overflow: auto;
        padding: 15px;
      }
      .turbines-box {
        width: 240px;
        margin-left: 5px;
        margin-bottom: 15px;
        padding: 10px;
        ul {
          overflow-y: auto;
          height: 100%;
        }
      }
    }
    .turbine-li {
      line-height: 38px;
      overflow-y: auto;
      background: #ececec;
      margin-bottom: 10px;
      padding: 8px;
      cursor: pointer;
      p {
        line-height: 32px;
      }
      &.cur {
        background: #122239;
        p {
          color: #e2a03f;
        }
      }
    }
  }
}
.unstyled-list {
  padding-left: 0;
  list-style: none;
}
</style>
