<template>
  <div class="login-container">
    <div class="top">
      <img class="login-logo" src="@/assets/images/logo-new.png" alt="" />
      <img class="login-right" src="@/assets/images/right.png" alt="" />
    </div>
    <div class="main">
      <div class="login-content-container">
        <!-- 底图 -->
        <img class="bg-img" src="@/assets/images/bg-new.png" alt="" />
        <!-- 风扇 -->
        <img src="@/assets/images/fanLeaf.png" class="bg-shan bg-shan1" alt="风扇" />
        <img src="@/assets/images/fanLeaf.png" class="bg-shan bg-shan2" alt="风扇" />
        <img src="@/assets/images/fanLeaf.png" class="bg-shan bg-shan3" alt="风扇" />
        <!-- 绿色块 -->
        <svg
          class="green"
          width="48px"
          height="41px"
          viewBox="0 0 48 41"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>绿色</title>
          <g id="登录页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="登录页备份" transform="translate(-246.000000, -639.000000)">
              <g id="绿色" transform="translate(246.566676, 639.943875)">
                <path
                  class="green-path"
                  d="M46.8538615,14.2410294 L46.8538615,28.7684127 L46.8322125,28.7688611 C46.3000727,34.6691137 36.0247474,39.375257 23.4269307,39.375257 C10.8291141,39.375257 0.553788805,34.6691137 0.0216489473,28.7688611 L0,28.7684127 L0,14.2410294 L46.8538615,14.2410294 Z"
                  id="形状结合"
                  fill="#0DFFB3"
                />
                <ellipse
                  id="椭圆形"
                  fill="#6AFFB6"
                  cx="23.4269307"
                  cy="13.8158822"
                  rx="23.4269307"
                  ry="13.8158822"
                />
              </g>
            </g>
          </g>
        </svg>
        <img src="@/assets/images/green-top.png" class="green-top" alt="" />
        <!-- 小球 -->
        <div class="ball-wrap">
          <img class="ball" src="@/assets/images/ball.png" alt="" />
        </div>
        <!-- 丝带 -->
        <img class="ribbon" src="@/assets/images/ribbon.png" alt="" />
        <!-- 立方体 -->
        <img class="cube" src="@/assets/images/cube.png" alt="" />
        <!-- 云 -->
        <img class="cloud" src="@/assets/images/cloud.png" alt="" />
      </div>
      <div class="login-panel-container">
        <div class="login-panel">
          <h1>智慧风场云端数据中心</h1>
          <div class="form">
            <div id="username-field">
              <span class="label">用户名</span>
              <el-input v-model="username" class="input-login" placeholder="请输入用户名" />
            </div>
            <div id="password-field">
              <span class="label">密码</span>
              <el-input
                v-model="password"
                @keyup.enter.native="verifyCode"
                class="input-login"
                placeholder="请输入密码"
                :type="showPwd ? 'text' : 'password'"
              >
                <div slot="suffix" class="pwdIcon" style="">
                  <img
                    v-if="showPwd"
                    slot="suffix"
                    src="@/assets/images/hidden.png"
                    alt=""
                    @click="togglePwd"
                  />
                  <img
                    v-else
                    slot="suffix"
                    src="@/assets/images/show.png"
                    alt=""
                    @click="togglePwd"
                  />
                </div>
              </el-input>
            </div>
            <button class="btn-login" type="button" @click="verifyCode">登 录</button>
            <!-- 飞书登录 -->
            <div class="login-feishu-wrapper">
              <a
                class="login-feishu"
                :href="feishuLoginUrl()"
              >
                <div class="feishu-container">
                  <div class="feishu" />
                </div>
                <div target="_blank" class="feishu-text">飞书登录</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent, onMounted, ref, onUnmounted,
} from 'vue';
import { showPopup } from '@sany/captcha-verify/showPopup';
import store from '@/store';
import router from '@/router';
import { uikit } from '@/utils';

export default defineComponent({
  name: 'Login',
  page: {
    title: ' 登录',
  },
  setup() {
    const username = ref('');
    const password = ref('');
    const showPwd = ref(false);
    function togglePwd() {
      showPwd.value = !showPwd.value;
    }
    // eslint-disable-next-line consistent-return
    function login(verifyData = {}) {
      const formData = { username: username.value, password: password.value, verifyData };
      if (!username.value || !password.value) {
        uikit.message('用户名密码错误');
        return false;
      }
      store.dispatch('login', formData).then(async (data) => {
        if (data.message === 'ok') {
          uikit.success('登录成功');
          await store.dispatch('fetchProfile');
          setTimeout(() => {
            const { redirect } = router.history.current.query;
            // SANYSSO
            // 如果是 SSO 登录，则需要刷新页面已实现登录成功重定向
            if (redirect && redirect.split('?')[0] === '/api/sso') {
              window.location.href = redirect;
            } else {
              const { profile } = store.state.auth;
              if (!profile.dept && profile.role === 'guest') {
                router.push('/register');
              } else if (redirect) {
                window.location.href = redirect;
              } else {
                window.location.href = '/';
              }
            }
          }, 1000);
        } else {
          uikit.error(`登录失败，${data.message}`);
        }
      });
    }

    function verifyCode() {
      if (!store.getters.app.isInternetAvaliabale) {
        const appId = window.SANY_SETTINGS.VUE_APP_CAPTCHA_VERIFY_APP_ID;
        showPopup(appId)
          .then((res) => {
            const { ret, ticket, randstr } = res;
            login({ ticket, randstr });
          })
          .catch((err) => {
            console.log(err);
            uikit.message('验证失败');
          });
      } else {
        login();
      }
    }
    function setFontSize() {
      document.documentElement.style['font-size'] = `${(window.innerWidth * 10) / 1920}px`;
    }

    const feishuLoginUrl = () => {
      let { host } = window.location;
      if (!(host.indexOf('inner.dc') >= 0 || host.indexOf('platform.dc') >= 0)) {
        host = 'inner-dc.sanywind.net';
      }
      return `https://open.work.sany.com.cn/open-apis/authen/v1/user_auth_page?app_id=cli_9fd2045d9fea1077&locale=zh&redirect_uri=https%3A%2F%2F${host}%2Fapi%2Ffeishu%2Flogin&state=`;
    };

    onMounted(() => {
      setFontSize();
      window.addEventListener('resize', setFontSize);
    });
    onUnmounted(() => {
      window.removeEventListener('resize', setFontSize);
      document.documentElement.style['font-size'] = 'unset';
    });

    return {
      username,
      password,
      showPwd,
      togglePwd,
      verifyCode,
      feishuLoginUrl,
    };
  },
});
</script>
<style lang="scss" scoped>
@function px2rem($px){
  @return $px/10 + rem;
}
.login-container {
  margin: 0;
  width: 100%;
  min-width: px2rem(1280);
  height: 100vh;
  overflow: hidden;
  background-color: #f3f7fb;
  background-size: cover;
  position: relative;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: self-start;
    position: absolute;
    top: 0;
    width: 100vw;

    .login-logo {
      margin: px2rem(60) 0 0 px2rem(59);
      width: px2rem(152);
    }
    .login-right{
      width: px2rem(318);
      height: px2rem(175);
    }
  }

  .main {
    height: 100vh;
    display: flex;
    flex: 1;

    .login-content-container {
      transform-origin: bottom left;
      position: relative;
      width: px2rem(1200);
      height: 100vh;

      @keyframes shan-move {
        0% {
          transform: rotate3d(0.3, 0, 1, 0deg);
        }

        100% {
          transform: rotate3d(0.3, 0, 1, 360deg);
        }
      }

      @keyframes flicker {
        0% {
          fill: #0dffb3;
        }

        50% {
          fill: #f3f7fb;
        }

        100% {
          fill: #0dffb3;
        }
      }

      @keyframes animX {
        0% {
          left: 0px;
        }

        100% {
          left: px2rem(350);
        }
      }

      @keyframes animY {
        0% {
          top: 0px;
        }

        100% {
          top: px2rem(180);
        }
      }

      @keyframes scale {
        0% {
          transform: scale(0.7);
        }

        50% {
          transform: scale(1);
        }

        100% {
          transform: scale(0.7);
        }
      }

      @keyframes cloud {
        0% {
          bottom: px2rem(778);
        }

        50% {
          bottom: px2rem(820);
        }

        100% {
          bottom: px2rem(778);
        }
      }

      .bg-img {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }

      .bg-shan {
        position: absolute;
        z-index: 20;
        width: px2rem(120);
        height: px2rem(120);
        animation: shan-move 3s linear infinite both;

        &.bg-shan1 {
          bottom: px2rem(623);
          left: px2rem(150);
        }

        &.bg-shan2 {
          bottom: px2rem(680);
          left: px2rem(258);
        }

        &.bg-shan3 {
          bottom: px2rem(276);
          left: px2rem(553);
        }
      }

      .green-top {
        position: absolute;
        bottom: px2rem(406);
        left: px2rem(241);
        width:px2rem( 55);
      }

      .green {
        position: absolute;
        bottom: px2rem(399);
        left: px2rem(245);
        width: px2rem(48);
        height: px2rem(41);
      }

      .green-path {
        animation: flicker 2s linear infinite both;
      }

      .ball-wrap {
        width: px2rem(350);
        height: px2rem(180);
        position: absolute;
        bottom: px2rem(511);
        left: px2rem(410);

        .ball {
          width: px2rem(42);
          position: absolute;
          animation: animX 10s cubic-bezier(0.36, 0, 0.64, 1) -5s infinite alternate,
            animY 10s cubic-bezier(0.36, 0, 0.64, 1) 0s infinite alternate,
            scale 20s cubic-bezier(0.36, 0, 0.64, 1) 0s infinite alternate;
        }
      }

      .ribbon {
        position: absolute;
        bottom: px2rem(527);
        left: px2rem(741);
        width: px2rem(58);
      }

      .cube {
        position: absolute;
        bottom: px2rem(560);
        left: px2rem(494);
        width: px2rem(265);
      }

      .cloud {
        position: absolute;
        bottom: px2rem(778);
        left: px2rem(550);
        width: px2rem(106);
        animation: cloud 3s linear infinite both;
      }
    }

    .login-panel-container {
      position: absolute;
      right: px2rem(320);
      top: 50%;
      transform: translate(0, -50%);

      .login-panel {
        width: px2rem(480);
        background: #ffffff;
        padding: px2rem(60) px2rem(40) px2rem(73) px2rem(40);

        h1 {
          font-size: px2rem(24);
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #182033;
          line-height: px2rem(33);
          text-align: center;
          padding-bottom: px2rem(30);
        }

        .form {
          .label {
            margin-top: px2rem(30);
            margin-bottom: px2rem(10);
            display: inline-block;
            font-size: px2rem(16);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #182033;
            line-height: px2rem(22);
          }
          ::v-deep(.el-input){
            input{
              height: px2rem(50);
              font-size: px2rem(14);
            }
            input::placeholder{
              font-size: px2rem(14);
              line-height: px2rem(40);
            }
          }

          .pwdIcon {
            cursor: pointer;
            height:100%;
            display: flex;
            align-items: center;
            padding-right:px2rem(20);
            img{
              width: px2rem(16);
            }
          }

          .btn-login {
            width: px2rem(400);
            height: px2rem(50);
            background: linear-gradient(180deg, #3f8cff 0%, #3e65ff 100%);
            border-radius: px2rem(8);
            border: none;
            outline: none;
            font-size: px2rem(18);
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: px2rem(25);
            margin-top: px2rem(40);
            cursor: pointer;
          }

          .login-feishu-wrapper {
            margin-top: px2rem(47);
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .login-feishu {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translateX(px2rem(-16));
          }

          .feishu-container {
            border-radius: 50%;
            display: flex;
            align-content: center;
            justify-content: center;

            .feishu {
              background: url("@/assets/images/feishu-new.png") no-repeat;
              background-size: contain;
              width: px2rem(24);
              height: px2rem(24);
              margin: auto;
              margin-top: px2rem(4);
            }
          }

          .feishu-text {
            font-size: px2rem(16);
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #182033;
            line-height: px2rem(22);
            margin-left: px2rem(12);
          }
        }
      }
    }
  }
}
</style>
