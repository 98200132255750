<script>
import { uikit } from '@/utils';
import { protoTurbineService as service, farmService } from '@/service';

export default {
  name: 'PrototypeTurbineReport',
  page: {
    title: '样机-文件下载',
  },
  props: ['checkedTurbine'],
  data() {
    return {
      treeDatas: [],
      loading: false,
      root: null,
      breadcrumbDatas: [],
      downloadStatus: null,
      Marker: '',
      prevMarker: '',
      pageSize: 100,
      currentPage: 0,
      progress: '',
      total: 0,
      IsTruncated: false,
      beforeMarks: {},
    };
  },
  computed: {
    percentage() {
      const { progress, total } = this.downloadStatus;
      return Math.floor((progress / total) * 100);
    },
  },
  watch: {
    checkedTurbine(newV, oldV) {
      this.getProtoCosReport();
    },
  },
  mounted() {
    this.getProtoCosReport();
  },
  methods: {
    getProtoCosReport() {
      const Prefix = `prototype/${this.checkedTurbine.pinyinCode}/`;
      this.breadcrumbDatas = Prefix.split('/');
      this.loading = true;
      this.fetchFileList({ Prefix });
    },
    fetchFileList(obj) {
      this.loading = true;
      const { Prefix, Marker, isBefore } = obj;
      this.prevMarker = this.Marker;
      const options = {
        Prefix,
        Marker: Marker || this.Marker,
        MaxKeys: this.currentPage === 0 ? this.pageSize + 1 : this.pageSize,
      };
      service.getProtoCosReport(options).then((results) => {
        this.loading = false;
        this.IsTruncated = results.IsTruncated === 'true';
        if (this.IsTruncated) {
          this.Marker = results.NextMarker;
        }
        const folders = results.CommonPrefixes.map((folder) => {
          if (folder) {
            const obj = {
              name: folder.Prefix.split('/')[folder.Prefix.split('/').length - 2],
              path: folder.Prefix,
              icon: 'el-icon-folder',
            };
            return obj;
          }
          return null;
        });
        const cosFiles = results.Contents.slice(1, results.Contents.length);
        const files = cosFiles.map((file) => {
          if (file.Size !== '0') {
            const obj = {
              name: file.Key.split('/')[file.Key.split('/').length - 1],
              path: file.Key,
              icon: 'el-icon-document',
              size: file.Size,
            };
            return obj;
          }
          return null;
        });
        this.treeDatas = folders.concat(files);
        if (this.IsTruncated && !isBefore) {
          this.beforeMarks[this.currentPage] = this.treeDatas.length > 0 ? this.treeDatas[this.treeDatas.length - 1].path : '';
          console.log(this.beforeMarks);
        }
      });
    },
    handleDownLoad(row) {
      if (row.size) {
        const a = document.createElement('a');
        a.href = `/api/v3/cos/exchange?key=${encodeURIComponent(row.path)}`;
        a.download = `${row.path.split('/').pop()}`;
        a.click();
      } else {
        const options = {
          path: row.path,
          pinyinCode: this.checkedTurbine.pinyinCode,
          turbineName: this.checkedTurbine.turbineName,
          from: this.checkedTurbine.startTime,
          to: this.checkedTurbine.endTime,
        };
        service.downloadProtoReport(options).then((res) => {
          const { count, jobId } = res;
          if (jobId) {
            this.progress = `正在下载 ${0 / count}`;
            this.checkDownloadJob(jobId);
          } else {
            uikit.message('下载失败！');
          }
        });
      }
    },
    checkDownloadJob(jobId) {
      farmService.checkDownloadJob(jobId).then(({ progress, total, result }) => {
        this.downloadStatus = { progress, total, result };
        if (progress === total) {
          this.progress = `下载完成(${progress}/${total})，正在压缩打包...`;
        } else {
          this.progress = `正在下载 ${progress}/${total}`;
        }
        if (result) {
          this.progress = 0;
          window.open(`/api/v3/temp/${result}`);
        } else {
          setTimeout(() => {
            this.checkDownloadJob(jobId);
          }, 5 * 1000);
        }
      });
    },
    handleDblclick(row, column, event) {
      this.breadcrumbDatas = row.path.split('/');
      this.Marker = '';
      this.fetchFileList({ Prefix: row.path });
    },
    handlerBreadcrumb(item) {
      const index = this.breadcrumbDatas.findIndex((data) => data === item);
      if (index === 0) {
        return;
      }
      this.breadcrumbDatas = this.breadcrumbDatas.slice(0, index + 1);
      const path = `${this.breadcrumbDatas.join('/')}/`;
      this.Marker = '';
      this.fetchFileList({ Prefix: path });
    },
    prevPage() {
      this.currentPage -= 1;
      const path = this.breadcrumbDatas.join('/');
      const Marker = this.beforeMarks[this.currentPage - 1] || '';
      this.Marker = Marker;
      this.fetchFileList({ Prefix: path, Marker, isBefore: true });
    },
    nextPage() {
      const path = this.breadcrumbDatas.join('/');
      this.currentPag += 1;
      this.fetchFileList({ Prefix: path });
    },
  },
};
</script>

<template>
  <div class="protoReportBox widget-content-area">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="mb-3">
      <el-breadcrumb-item
        v-for="(item, idx) in breadcrumbDatas"
        :key="idx + ''"
        @click.native="handlerBreadcrumb(item)"
      >
        {{ item }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <div v-if="progress" class="browser-list">
        <div class="w-browser-details">
          <div class="w-browser-info">
            <p>{{ progress }}</p>
          </div>
          <el-progress
            class="progress-indicator"
            :text-inside="true"
            :stroke-width="18"
            :percentage="percentage"
          />
        </div>
      </div>
    </div>
    <div class="table-box">
      <el-table v-loading="loading" height="650" :data="treeDatas" @row-dblclick="handleDblclick">
        <el-table-column label="名称">
          <template slot-scope="scope">
            <i :class="scope.row.icon" style="font-size: 20px" />
            <span style="margin-left: 10px">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="文件大小">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.size || "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80px">
          <template slot-scope="scope">
            <el-button size="small" type="primary" @click="handleDownLoad(scope.row)">
              下载
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="IsTruncated" class="btn-box">
        <el-button :disabled="currentPage === 0" type="primary" @click="prevPage">
          上一页
        </el-button>
        第 {{ currentPage + 1 }} 页
        <el-button type="primary" :disabled="!IsTruncated" @click="nextPage"> 下一页 </el-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.protoFaultBox {
  .layout-spacing {
    padding: 0;
  }
}
</style>

<style lang="scss" scoped>
.protoFaultBox {
  // margin: 0 -15px;
  height: calc(100% - 15px);
  box-shadow: 0 4px 6px 0 rgb(85 85 85 / 9%), 0 1px 20px 0 rgb(0 0 0 / 8%),
    0px 1px 11px 0px rgb(0 0 0 / 6%);
  margin: 5px 10px;
  overflow: auto;
}
.btn-box {
  text-align: right;
  margin-top: 10px;
}

.progress-indicator {
  width: 30%;
  min-width: 200px;
}
</style>
