// TODO: 关联 store
import Main from '@/views/main.vue';
import Login from '@/views/login';

import Home from '@/views/home/index.vue';
import { CvtFarmExplorer, CvtTurineExplorer } from '@/views/cvtExplorer';
import UserHistory from '@/views/user/user-history/index.vue';
import IframeComponent from '@/views/IframeComponent.vue';
import {
  DriveChainTool, BladeTool, CmsModelSummary, CmsTurbineModelResult, CmsFarmModelResults,
} from '@/views/cms';
import TurbineOpsManage from '@/views/turbineOpsManage';
import Changelog from '@/views/ChangelogView.vue';
import { PrototypeTurbine } from '@/views/prototypeTurbine';
import Regist from '@/components/Regist.vue';
import store from '@/store';
import Empty from '@/components/empty.vue';

const cmsRouterConfig = [
  {
    path: '/cms/driveChainTool',
    name: 'driveChainTool',
    component: DriveChainTool,
    meta: {
      breadcrumbs: [
        {
          display: '传动链数据分析',
          canGoBack: false,
        }],
    },
  },
  {
    path: '/cms/bladeTool',
    name: 'bladeTool',
    component: BladeTool,
    meta: {
      breadcrumbs: [
        {
          display: '叶片数据分析',
          canGoBack: false,
        }],
    },
  },
  {
    path: '/cms/modelSummary',
    name: 'cmsModelSummary',
    component: CmsModelSummary,
    meta: {
      breadcrumbs: [{
        display: 'cms模型预警',
        canGoBack: false,
      }],
    },
  },
  {
    path: '/cms/farm/:pinyinCode/model/result',
    name: 'cmsTurbineModelResult',
    component: CmsTurbineModelResult,
    meta: {
      breadcrumbs: [{
        display: 'cms风机模型预警',
        canGoBack: true,
      }],
    },
  },

];

// 用户管理模块
const userRouteConfig = [
  {
    path: '/monitor',
    name: 'monitor',
    component: IframeComponent,
    meta: {
      breadcrumbs: [{
        display: '云端监控',
        canGoBack: false,
      }],
    },
  },
];
const cvtExplorerRouteConfig = [
  {
    path: '/cvt-farm-explorer',
    name: 'cvtFarmExplorer',
    component: CvtFarmExplorer,
    meta: {
      // theme: 'dark',
      breadcrumbs: [{
        display: '风场变流器数据下载',
        canGoBack: false,
      }],
    },
  },
  {
    path: '/cvtData/:pinyinCode/turbine-explorer/:currentDate',
    name: 'cvtDataTurbineExplorer',
    component: CvtTurineExplorer,
    meta: {
      // theme: 'dark',
      breadcrumbs: [{
        display: '全场',
        canGoBack: true,
      },
      {
        display: '风机变流器数据下载',
        canGoBack: false,
      }],
    },
  },
];

// // 关键点控制
const turbineOpsConfig = [
  {
    name: 'turbineOpsManage',
    path: '/turbine-ops-manage',
    component: TurbineOpsManage,
    meta: {
      breadcrumbs: [{
        display: '关键点控制管理',
        canGoBack: false,
      }],
    },
  },
];

const getDefaultItem = () => {
  const defaultRouter = (window.SANY_SETTINGS && window.SANY_SETTINGS.DEFAULT_ROUTE) || {};
  const rdr = {
    path: '/',
    redirect: '/login',
  };
  if (defaultRouter) {
    rdr.redirect = defaultRouter;
  } else {
    rdr.redirect = '/micro/main/fault-analy-search';
  }

  // if (defaultRouter === 'farmExplorer') {
  //   rdr.redirect = '/micro/main/farm-explorer';
  // } else {
  //   rdr.redirect = '/micro/main/fault-analy-search';
  // }
  return rdr;
};
export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      disableAuth: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters.isLoggedIn) {
          next({ name: '/' });
        } else {
          next();
        }
      },
    },
  },
  {
    path: '/register',
    name: 'register',
    component: Regist,
    meta: {
      breadcrumbs: [{
        display: '用户角色注册',
        canGoBack: false,
      }],
    },
  },
  {
    path: '/main',
    component: Main,
    children: [
      {
        path: '/fault-history',
        name: 'faultHistory',
        component: UserHistory,
        meta: {
          breadcrumbs: [],
        },
      },
      {
        path: '/user-download',
        name: 'userDownload',
        // 重定向到main中的/user-download
        redirect: '/micro/main/user-download',
      },
      ...cmsRouterConfig,
      ...userRouteConfig,
      ...turbineOpsConfig,
      ...cvtExplorerRouteConfig,
      {
        path: '/changelog',
        name: 'changelog',
        component: Changelog,
        meta: {
          breadcrumbs: [{
            display: '系统更新日志',
            canGoBack: false,
          }],
        },
      },
    ],
  },
  // 微应用app 路径
  {
    path: '/micro/*',
    name: 'micro',
    component: Main,
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
  },
  {
    path: '/prototype/turbine',
    name: 'prototypeTurbine',
    component: PrototypeTurbine,
  },
  {
    path: '/cms/:pinyinCode/:part/model-results',
    name: 'cmsFarmModelResults',
    component: CmsFarmModelResults,
  },
  getDefaultItem(),
  {
    path: '*',
    component: Empty,
    // redirect: '/',
  },
];
