<script>
import { userService as service } from '@/service';
import _ from 'lodash';
import { routeNamesfnList } from '@/pathFn';
import sharedActions from '@/shared/actions';
import { menusList } from './menus';
import ScrollBar from './Scrollbar';

export default {
  name: 'Sidebar',
  components: {
    ScrollBar,
  },
  data() {
    return {
      runTime: '0天0时0分0秒',
      menusList,
      localUrl: '',
      defaultActive: '',
      isExpansionAll: true,
    };
  },
  computed: {
    profile() {
      return this.$store.state.auth.profile;
    },
    isCloudDesktop() {
      return this.$store.state.app.isCloudDesktop;
    },
    isCollect: () => {
      return (currentMenuName) => [].includes(currentMenuName);
    },
    defaultOpeneds() {
      return this.isExpansionAll ? _.map(menusList, (item) => {
        return item.menuName;
      }) : [];
    },
    expansionAllText() {
      return this.isExpansionAll ? '收起全部菜单' : '展开全部菜单';
    },
    expansionAllTextDisplay() {
      return this.$store.state.app.sidebarIsCollapse ? 'none' : 'unset';
    },
    expansIcon() {
      return this.isExpansionAll ? 'el-icon-caret-bottom' : 'el-icon-caret-right';
    },
  },
  mounted() {
    this.localUrl = window.location.origin;
    // const sDate1 = '2020-08-07 00:00:00';
    // const date1 = new Date(sDate1.replace(/-/g, '/'));
    // setInterval(() => {
    //   this.dateDiff(date1);
    // }, 1000);
  },
  methods: {
    menuIsActive(routeName = [], menu) {
      let res;
      if (this.$route.name.includes('micro')) {
        res = routeName.includes(this.$route.path);
        if (res && !menu.child) {
          this.defaultActive = menu.menuName;
          this.$store.dispatch('addVisitedView', menu);
          sharedActions.setGlobalState({
            app: 'main',
            compName: menu.comName,
            type: 'add',
          });
        }
        return res;
      }
      res = routeName.includes(this.$route.name);
      if (res && !menu.child) {
        this.defaultActive = menu.menuName;
        this.$store.dispatch('addVisitedView', menu);
      }
      return res;
    },
    getNode(tree, menuName, key) {
      // eslint-disable-next-line no-restricted-syntax
      for (const node of tree) {
        if (node[key] === menuName) {
          return node;
        }
        if (node.child) {
          const result = this.getNode(node.child, menuName, key);
          if (result) {
            return result;
          }
        }
      }
      return null;
    },
    handleMenuClick(menuName) {
      const menu = this.getNode(menusList, menuName, 'menuName');
      this.goto(menu, {});
    },
    goto(menu, params) {
      if (menu.menuName === 'home') {
        const routeData = this.$router.resolve({ name: 'home' });
        window.open(routeData.href, '_blank');
        return;
      }
      if (menu.route && menu.route !== '') {
        if (menu.route === 'prototypeTurbine') {
          this.createUserFnCount(menu.menuName);
          const routeData = this.$router.resolve({ name: 'prototypeTurbine' });
          window.open(routeData.href, '_blank');
          return;
        }
        // if (menu.route === 'farmsControlOverviewNew') {
        //   this.createUserFnCount(menu.menuName);
        //   const routeData = this.$router.resolve({ name: 'farmsControlOverviewNew' });
        //   window.open(routeData.href, '_blank');
        //   return;
        // }
        // 地形分析兼容处理
        if (menu.route === 'mapAnalysis') {
          window.open('/micro-apps/map/map-analysis');
          return;
        }
        // // 能管分析兼容处理
        if (menu.route === 'energyManagement') {
          window.open('/micro-apps/tool/energy-management');
          return;
        }
        // cms分析工具
        if (menu.route === 'driveChainTool') {
          window.open('/micro-apps/tool/analyze-tool?type=cms');
        }
        // micro-apps弹出
        if (menu.route?.startsWith('/micro-apps')) {
          this.createUserFnCount(menu.menuName);
          window.open(menu.route);
          return;
        }
        // 微应用跳转方式
        if (menu.route.includes('/micro/')) {
          this.$store.dispatch('addVisitedView', {
            ...menu,
            params,
          });
          sharedActions.setGlobalState({
            app: 'main',
            compName: menu.comName,
            type: 'add',
          });
          // 更改菜单名称
          this.$emit('updateMenuName', menu.menuName);
          this.$router.push({ path: menu.route, params, query: menu.query });
        } else if (menu.route.includes('/micro/chonky/')) {
          this.$store.dispatch('addVisitedView', {
            ...menu,
            params,
          });
          sharedActions.setGlobalState({
            app: 'chonky',
            compName: menu.comName,
            type: 'add',
          });
          // 更改菜单名称
          this.$emit('updateMenuName', menu.menuName);
          this.$router.push({ path: menu.route, params, query: menu.query });
        } else {
          // 主app内跳转方式
          this.$store.dispatch('addVisitedView', {
            ...menu,
            params,
          });
          this.$emit('getMainInclude', menu.comName);
          this.$router.push({ name: menu.route, params, query: menu.query });
        }
      } else {
        // TODO? 改为读取环境变量 --- 改掉
        // eslint-disable-next-line no-lonely-if
        if (menu.menuName === 'yunwei') {
          if (this.localUrl.indexOf('beta-dc.sanywind.net') > -1) {
            window.open('https://beta-ops.sanywind.net');
          } else if (this.localUrl.indexOf('test-dc.sanywind.net') > -1) {
            window.open('https://test-ops.sanywind.net');
          } else {
            window.open('https://ops.sanywind.net');
          }
        } else if (menu.menuName === 'zhishi') {
          this.createUserFnCount(menu.menuName);
          if (this.localUrl.indexOf('dc.sanywind.net') !== -1) {
            window.open('https://wiki.sanywind.net/portal');
          } else {
            // window.open('http://10.162.4.40:21003/portal');
            window.open('https://wiki.sanywind.net/portal');
          }
        } else {
          window.open(menu.href);
        }
      }
    },
    dateDiff(date1) {
      const date2 = new Date();
      // eslint-disable-next-line radix
      this.getDuration(parseInt(date2.getTime() - date1.getTime()));
    },
    getDuration(time) {
      const days = time / 1000 / 60 / 60 / 24;
      const daysRound = Math.floor(days);
      const hours = time / 1000 / 60 / 60 - 24 * daysRound;
      const hoursRound = Math.floor(hours);
      const minutes = time / 1000 / 60 - 24 * 60 * daysRound - 60 * hoursRound;
      const minutesRound = Math.floor(minutes);
      const seconds = time / 1000 - 24 * 60 * 60 * daysRound - 60 * 60 * hoursRound - 60 * minutesRound;
      // eslint-disable-next-line radix
      this.runTime = `${daysRound}天${hoursRound}时${minutesRound}分${parseInt(seconds)}秒`;
    },
    getMenuAvailability(menu) {
      if (menu.menuName === 'mapAnalysis' && this.isCloudDesktop) {
        return false;
      }
      if (this.profile.username === 'admin') {
        return true;
      }
      if (this.profile.menus && this.profile.menus.indexOf(menu.menuName) > -1) {
        return true;
      }
      if (menu.hidden && this.localUrl.includes(menu.hidden)) {
        return false;
      }
      return true;
    },
    createUserFnCount(menuName) {
      const routeName = routeNamesfnList.filter((item) => item.routeNames.includes(menuName))[0];
      const fn = routeName ? routeName.fn : [];
      if (fn.length > 0) {
        const option = {
          routeName: 'prototypeTurbine',
          fn,
        };
        service.createUserFnCount(option);
      }
    },
    changeExpansion() {
      if (this.$store.state.app.sidebarIsCollapse) return;
      this.isExpansionAll = !this.isExpansionAll;
      // 300ms(菜单动画时间)
      setTimeout(() => {
        this.$refs.scrollBarRef.scroll.refresh();
      }, 300);
    },
  },
};
</script>

<template>
  <!--  BEGIN SIDEBAR  -->
  <div class="sidebar-wrapper sidebar-theme">
    <nav id="sidebar">
      <div class="sidebar-top" @click="changeExpansion">
        <span class="text" :style="{ display: expansionAllTextDisplay }">{{ expansionAllText }}</span>
        <span class="expans-icon">
          <i :class="expansIcon" />
        </span>
      </div>
      <div class="menu-container">
        <ScrollBar ref="scrollBarRef">
          <el-menu
            :collapse="$store.state.app.sidebarIsCollapse"
            :default-active="defaultActive"
            :default-openeds="defaultOpeneds"
            active-text-color="#3E65FF"
            text-color="#182033"
            class="sany-menu"
            @select="handleMenuClick"
          >
            <div
              v-for="(menu, index) in menusList"
              v-show="getMenuAvailability(menu)"
              :key="index + ''"
              class="menu"
              :class="{
                'menu-heading': `${menu.menuTitle}`,
                active: menuIsActive(menu.isActive, menu),
              }"
            >
              <el-menu-item :index="menu.menuName" v-if="!menu.menuTitle && !menu.child">
                <span class="iconfont iconfont-menu" :class="'icon-' + menu.icon" />
                <span slot="title" class="menu-text">{{ menu.display }}</span>
              </el-menu-item>
              <el-submenu :index="menu.menuName" v-if="!menu.menuTitle && menu.child">
                <template slot="title">
                  <span class="iconfont iconfont-menu" :class="'icon-' + menu.icon" />
                  <span class="menu-text">{{ menu.display }}</span>
                </template>
                <el-menu-item-group
                  v-for="(submenu, idx) in menu.child"
                  :key="idx + ''"
                  :class="{ active: menuIsActive(submenu.isActive, submenu) }"
                >
                  <el-menu-item
                    :index="submenu.menuName"
                  >
                    {{ submenu.display }}
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </div>
          </el-menu>
        </ScrollBar>
      </div>
    </nav>
  </div>
  <!--  END SIDEBAR  -->
</template>

<style lang="scss" scoped>
.collect {
  color: #ff9f1d !important;
}
#sidebar {
  height: calc(100vh - 40px);
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.12);
  .theme-brand li {
    &.theme-logo {
      img {
        width: 130px;
        height: auto;
      }
    }
    &.theme-text {
      a {
        font-size: 18px !important;
        padding-left: 0px;
      }
    }
  }

  .menu {
    cursor: pointer;
    &.active {
      a {
        color: #3e65ff !important;
      }
    }
    // 子菜单
    .submenu {
      li.active {
        & > a {
          color: #3e65ff !important;
          background: transparent !important;
          font-weight: 600;
        }
      }
    }
  }

  ul.menu-scrollbar-container {
    height: auto;
    padding: 0;
  }
  .menu-container {
    position: relative;
    margin: auto;
    width: 100%;
    height: calc(100% - 40px);
  }
}
.el-scrollbar__wrap {
  margin: 0 !important;
}
</style>
<style lang="scss">
#sidebar {
  padding: 8px;
  .is-active{
    >div{
      color: #3E65FF !important;
    }
  }
  .sidebar-top{
    height: 34px;
    line-height: 34px;
    background: rgba(62,101,255,0.16);
    color: #3E65FF;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    span.text{
      padding-left: 12px;
    }
    span.expans-icon{
      display: inline-block;
      width: 34px;
      height: 34px;
      text-align: center;
    }
  }
  .el-menu {
    border-right: none;
    &.el-menu--collapse{
      width: 34px;
      .menu-heading{
        margin-top: 9px;
      }
      .el-menu-item{
        height: 34px;
        line-height: 34px;
        .iconfont-menu{
          margin-right: 0px !important;
          font-size: 14px;
        }
      }
      .el-submenu{
        .el-submenu__title{
          height: 34px !important;
          line-height: 34px !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
          text-align: center;
          border-radius: 4px;
          .iconfont-menu{
            margin-right: 0px !important;
            font-size: 14px;
          }
          .menu-text{
            display: none;
          }
          .el-icon-arrow-right{
            display: none;
          }
        }
      }
    }
    .heading {
      height: 38px;
      display: flex;
      align-items: center;
      span:nth-child(1) {
        display: inline-block;
        width: 2px;
        height: 14px;
        background-color: #5D6370;
        margin: 0 8px 0 10px;
      }
      span:nth-child(2) {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 500;
        color: #5D6370;
        line-height: 20px;
      }
    }
    .el-menu-item {
      height: 38px;
      line-height: 38px;
      border-radius: 4px;
      padding-left:10px !important;
      width: 100%;
      min-width: 100%;
      font-weight: 400;
      &.is-active {
        background: rgba(62, 101, 255, 0.1) !important;
        font-weight: 600;
        span {
          color: #3e65ff !important;
          font-weight: 600;
        }
      }
      .iconfont-menu{
        margin-right: 12px;
        font-size: 14px;
      }
      div.el-tooltip{
        padding: 0px 0px !important;
        width: 34px !important;
        text-align: center !important;
      }
    }
    .el-submenu {
      div.el-submenu__title {
        padding-left:10px !important;
        padding-right: 0px !important;
        height: 38px !important;
        line-height: 38px !important;
        margin-left: 0px;
        .iconfont-menu{
          margin-right: 12px;
          font-size: 14px;
          &.icon-tubiao-dashboard {
            font-size: 16px;
          }
        }
        i {
          margin-top: -5px;
          right: 11px;
          &:before {
            content: "\e791";
          }
        }
      }
      &.is-opened {
        > div.el-submenu__title {
          > i {
            transform: rotateZ(90deg);
            right: 12px !important;
          }
        }
      }
      .el-menu--inline {
        .el-menu-item-group {
          .el-menu-item-group__title {
            padding: 0 !important;
          }
          .el-menu-item {
            padding-left: 46px !important;
          }
        }
      }
    }
  }
}
div.el-menu--vertical{
  left: 40px !important;
  border-radius: 4px;
  .el-menu--popup{
    padding: 0;
    min-width: unset;
    .el-menu-item-group{
      .el-menu-item-group__title{
        padding: 0 !important;
      }
      .el-menu-item{
        height: 34px !important;
        line-height: 34px !important;
      }
    }
  }
}
</style>
