<script>
import {
  LayersIcon, DownloadIcon, CompassIcon, CalendarIcon, InfoIcon,
} from 'vue-feather-icons';
import FarmFault from './FarmFault';
import FarmDownload from './FarmDownload';
import FarmInfo from './FarmInfo';
import FarmReport from './FarmReport';

export default {
  name: 'Home',
  components: {
    FarmInfo,
    FarmFault,
    FarmDownload,
    FarmReport,
    LayersIcon,
    DownloadIcon,
    CompassIcon,
    CalendarIcon,
    InfoIcon,
  },
  props: ['pinyinCode'],
  data() {
    return {
      menuList: [
        {
          title: '风场信息',
          value: 'FarmInfo',
          icon: 'LayersIcon',
        },
        {
          title: '故障',
          value: 'FarmFault',
          icon: 'InfoIcon',
        },
        {
          title: '下载',
          value: 'FarmDownload',
          icon: 'DownloadIcon',
        },
        {
          title: '报表',
          value: 'FarmReport',
          icon: 'CalendarIcon',
        },
        {
          title: '地形',
          value: 'map',
          icon: 'CompassIcon',
        },

      ],
      curMenu: {
        title: '风场信息',
        value: 'FarmInfo',
      },
      editableTabsValue: '',
      editableTabs: [],
      tabIndex: 1,
      curIdx: 0,
      addTabObj: {},
    };
  },
  computed: {
    farmLists() {
      return this.$store.state.farm.list;
    },
    farm() {
      return this.$store.state.farm.list.filter((item) => item.pinyinCode === this.pinyinCode)[0];
    },
  },
  mounted() {
    this.editableTabs.push(this.farm);
    this.editableTabsValue = this.farm.pinyinCode;
  },
  methods: {
    // 点击侧边栏
    menuHandleClick(item) {
      if (item.value === 'map') {
        window.open(`/micro-apps/map/map-analysis?pinyinCode=${this.pinyinCode}`);
      } else {
        this.curMenu = item;
      }
    },
  },
};
</script>

<template>
  <div class="home-content">
    <div class="home-left-menu">
      <ul class="list-unstyled">
        <li v-for="(item, index) in menuList" :key="index + ''" :class="{ active: curMenu.value === item.value }" @click="menuHandleClick(item)">
          <el-tooltip class="item" effect="dark" :content="item.title" placement="right">
            <Component :is="item.icon" />
          </el-tooltip>
        </li>
      </ul>
    </div>
    <div class="home-right-content">
      <Component :is="curMenu.value" :farm-code="pinyinCode" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.home-content{
  height: 100%;
  width: 100%;
  position: relative;
  .home-left-menu{
    width: 60px;
    height: 100%;
    background: #060818;
    position: absolute;
    top: 0px;
    li{
      padding: 10px 0 10px 15px;
      margin-bottom: 7px;
      list-style: none;
      cursor: pointer;
    }
    li.active{
      background: rgba(59, 63, 92, 0.35);
    }
  }
  .home-right-content{
    height: 100%;
    width: 100%;
    padding-left: 70px;
    padding-right: 10px;
    overflow: hidden;
    margin-top: 10px;
    padding:0 10px 15px 70px;
    .tab-box{
      width: 100%;
      height: 100%;
      .el-tabs,.el-tab-pane{
        height: 100%;
      }
      ::v-deep .el-tabs__header{
        border-bottom: 0 none;
         box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
      }
      ::v-deep .el-tabs__content{
        padding:0 20px 20px;
        height: calc(100% - 41px);
      }
    }
  }
}
</style>
