<script>
import { otherService as service } from '@/service';

export default {
  name: 'CosLink',
  props: ['name', 'cosKey'],
  data() {
    return {
      link: '',
    };
  },
  computed: {
    imgStyle() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
  },
  methods: {
    async loadLink() {
      // const res = await service.exchangeUrl(encodeURIComponent(this.cosKey));
      await service.downLoadFile(this.cosKey);
    },
  },
};
</script>

<template>
  <a @click="loadLink">
    {{ name }}
  </a>
</template>

<style scoped lang="scss">
.analy-ul {
  display: flex;
  flex-wrap: wrap;
  & li a {
    margin-right: 5px;
    color: #2196f3;
    cursor: pointer;
    &:hover {
      color: #0f6674;
      opacity: 0.9;
    }
  }
}
</style>
