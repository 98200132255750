<script>
import appConfig from '@/app.config';
import Layout from '@/views/layouts/main.vue';
import CmsHeader from '@/views/cms/CmsHeader';
import ToolBox from '@/views/cms/ToolViewer';

export default {
  name: 'bladeTool',
  page: {
    title: '叶片数据分析',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    CmsHeader,
    ToolBox,
  },
  data() {
    return {
      cludesType: ['叶片'],
      trendCharts: null,
      waveCharts: null,
      frequencyList: ['2560', '51200'],
    };
  },
  methods: {
    selectOptionsChange(options) {
      this.$refs.toolBox.selectOptionsChange();
    },

  },
};
</script>

<template>
  <Layout>
    <div class="layout-spacing">
      <div class="widget-content widget-content-area">
        <!--搜索条件-->
        <CmsHeader :cludes-type="cludesType" :frequency-list="frequencyList" />
        <ToolBox ref="toolBox" :data-type="'blade'" />
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" scoped>
.layout-spacing{
  padding-bottom: 0;
  .widget-content{
    height: calc(100vh - 88px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.06);
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
    }
    /* 滚动条滑块 */
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: rgba(0, 0, 0, 0.12);
      -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
