<script>
import moment from 'moment';

import appConfig from '@/app.config';
import { uikit } from '@/utils';
import { protoTurbineService as service } from '@/service';

export default {
  name: 'TurbineList',
  page: {
    title: '样机列表',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  props: ['turbineList'],
  data() {
    return {
      keyword: '',
      dialogVisible: false,
      protoTurbineObj: {
        partsMessage: {},
        protoName: '',
        farm: {},
        turbine: {},
        status: '',
      },
      turbines: [],
      isAdd: false,
      protoStages: ['样机验证中', '样机阶段结束'],
      kinds: ['水平轴', '垂直轴'],
      rules: {
        protoName: [{ required: true, message: '请输入样机名称', trigger: 'blur' }],
        farm: [{ required: true, message: '请选择风场', trigger: 'blur' }],
        turbine: [{ required: true, message: '请选择风机', trigger: 'blur' }],
        status: [{ required: true, message: '请选择样机阶段', trigger: 'blur' }],
        dateRange: [{ required: true, message: '请选择样机时间', trigger: 'blur' }],
        turbineType: [{ required: true, message: '请输入样机机型', trigger: 'blur' }],
      },
    };
  },
  computed: {
    farms() {
      return this.$store.state.farm.list;
    },
    tableData() {
      if (this.keyword) {
        return this.turbineList.filter((i) => {
          const searchField = {
            protoName: i.protoName,
            farmName: i.farmName,
            turbineName: i.turbineName,
          };
          return Object.keys(searchField).some(
            (key) => String(i[key]).toLowerCase().indexOf(this.keyword) > -1,
          );
        });
      }
      return this.turbineList;
    },
  },
  methods: {
    doSearch() {
      if (!this.keyword) {
        this.$parent.getTurbinesList();
      }
    },
    handleAdd() {
      this.isAdd = true;
      this.dialogVisible = true;
    },
    handleDetail(row) {
      this.$emit('onTurbineChange', row);
      this.$parent.curMenu = {
        title: '数据下载',
        value: 'TurbineData',
      };
    },
    handleEdit(row) {
      this.isAdd = false;
      this.dialogVisible = true;
      const farm = this.farms.filter((f) => row.pinyinCode === f.pinyinCode)[0];
      this.turbines = farm.turbines;
      const turbine = this.turbines.filter((t) => t.turbineName === row.turbineName)[0];
      this.protoTurbineObj = {
        id: row.id,
        protoName: row.protoName,
        farm,
        turbine,
        status: row.status,
        dateRange: [row.startTime || '', row.endTime || ''],
        turbineType: row.turbineType,
        partsMessage: {
          ...row,
        },
      };
    },
    farmChange(val) {
      this.protoTurbineObj.turbine = {};
      this.turbines = val.turbines;
      if (this.turbines.length === 0) {
        uikit.error('该风场下没有风机！');
      }
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const options = {
            id: this.protoTurbineObj.id,
            protoName: this.protoTurbineObj.protoName,
            partsMessage: this.protoTurbineObj.partsMessage,
            pinyinCode: this.protoTurbineObj.farm.pinyinCode,
            farmName: this.protoTurbineObj.farm.farmName,
            farmCode: this.protoTurbineObj.farm.farmCode,
            address: this.protoTurbineObj.farm.address,
            latitude: this.protoTurbineObj.farm.latitude,
            longitude: this.protoTurbineObj.farm.longitude,
            province: this.protoTurbineObj.farm.province,
            turbineName: this.protoTurbineObj.turbine.turbineName,
            turbineId: this.protoTurbineObj.turbine.turbineId,
            status: this.protoTurbineObj.status,
            turbineType: this.protoTurbineObj.turbineType,
            startTime: moment(this.protoTurbineObj.dateRange[0]).format('YYYY-MM-DD'),
            endTime: moment(this.protoTurbineObj.dateRange[1]).format('YYYY-MM-DD'),
          };
          service.createOrUpdateProtoTurbine(options).then((result) => {
            if (result) {
              uikit.message('保存成功！');
              this.$parent.getTurbinesList();
              this.dialogVisible = false;
              // location.reload()
            } else {
              uikit.error('操作失败！');
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
        return true;
      });
    },
    resetForm() {
      this.dialogVisible = false;
      this.protoTurbineObj = {
        partsMessage: {},
        protoName: '',
        farm: {},
        turbine: {},
        status: '',
      };
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row';
      }
      return '';
    },
    gotoContent(id) {
      this.$parent.isShowEdit = true;
    },
  },
};
</script>

<template>
  <div class="widget-content-area">
    <div class="filter-form">
      <el-row class="filter-bar" :gutter="20">
        <el-col :span="10">
          <el-input
            v-model.trim="keyword"
            type="text"
            size="medium"
            placeholder="样机名称/风场名称/风机号等关键字"
          >
            <el-button slot="append" icon="el-icon-search" @click.prevent="doSearch" />
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button icon="el-icon-plus" size="medium" type="primary" @click="handleAdd">
            新增
          </el-button>
        </el-col>
      </el-row>
    </div>
    <el-table
      :data="tableData"
      :row-style="{ height: 0 + 'px' }"
      :cell-style="{ padding: 5 + 'px', fontSize: '13px' }"
      :row-class-name="tableRowClassName"
      :header-cell-style="{ 'text-align': 'center' }"
    >
      <el-table-column prop="protoName" label="样机名称" width="150" />
      <el-table-column prop="farmName" label="所属风场" width="150" />
      <el-table-column prop="turbineName" label="所属风机" width="150">
        <template slot-scope="scope">
          {{ scope.row.turbineName }}({{ scope.row.ownerTurbineName }})
        </template>
      </el-table-column>
      <el-table-column prop="startTime" label="样机开始时间" width="120" />
      <el-table-column prop="endTime" label="样机结束时间" width="120" />
      <el-table-column prop="status" label="样机阶段" width="120" />
      <el-table-column label="大部件信息">
        <el-table-column prop="alternator" label="发电机" width="120" />
        <el-table-column prop="gearBox" label="齿轮箱" width="120" />
        <el-table-column prop="converter" label="变流器" width="120" />
        <el-table-column prop="pitch" label="变桨" width="120" />
        <el-table-column prop="yaw" label="偏航" widt h="120" />
      </el-table-column>
      <el-table-column label="操作" width="160">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleDetail(scope.row)"> 详情 </el-button>
          <el-button type="warning" size="mini" @click="handleEdit(scope.row)"> 编辑 </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="样机信息"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @close="resetForm"
    >
      <el-form
        ref="ruleForm"
        :rules="rules"
        size="small"
        :inline="true"
        class="demo-form-inline"
        label-width="130px"
        :model="protoTurbineObj"
      >
        <el-form-item label="样机名称" prop="protoName">
          <el-input v-model="protoTurbineObj.protoName" placeholder="请输入样机名称" />
        </el-form-item>
        <el-form-item label="所属风场" prop="farm">
          <el-select
            v-model="protoTurbineObj.farm"
            filterable
            value-key="farmId"
            @change="farmChange"
          >
            <el-option v-for="f in farms" :key="f.pinyinCode" :value="f" :label="f.farmName" />
          </el-select>
        </el-form-item>
        <el-form-item label="所属风机" prop="turbine">
          <el-select v-model="protoTurbineObj.turbine" filterable value-key="turbineId">
            <el-option
              v-for="t in turbines"
              :key="t.turbineId + ''"
              :value="t"
              :label="t.turbineName"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="样机阶段" prop="status">
          <el-select v-model="protoTurbineObj.status">
            <el-option v-for="s in protoStages" :key="s" :value="s" :label="s" />
          </el-select>
        </el-form-item>
        <el-form-item label="持续时间" prop="dateRange">
          <el-date-picker
            v-model="protoTurbineObj.dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item label="样机机型" prop="turbineType">
          <el-input v-model="protoTurbineObj.turbineType" placeholder="请输入样机机型" />
        </el-form-item>
        <el-form-item label="发电机">
          <el-input
            v-model="protoTurbineObj.partsMessage.alternator"
            placeholder="请输发电机厂商"
          />
        </el-form-item>
        <el-form-item label="齿轮箱">
          <el-input v-model="protoTurbineObj.partsMessage.gearBox" placeholder="请输入齿轮箱厂商" />
        </el-form-item>
        <el-form-item label="变流器">
          <el-input
            v-model="protoTurbineObj.partsMessage.converter"
            placeholder="请输入变流器厂商"
          />
        </el-form-item>
        <el-form-item label="变桨">
          <el-input v-model="protoTurbineObj.partsMessage.pitch" placeholder="请输入变桨厂商" />
        </el-form-item>
        <el-form-item label="偏航">
          <el-input v-model="protoTurbineObj.partsMessage.yaw" placeholder="请输入偏航厂商" />
        </el-form-item>
        <el-form-item label="类型" prop="kind">
          <el-select v-model="protoTurbineObj.partsMessage.kind">
            <el-option v-for="k in kinds" :key="k" :value="k" :label="k" />
          </el-select>
        </el-form-item>
        <el-form-item label="额定功率">
          <el-input
            v-model="protoTurbineObj.partsMessage.ratedPower"
            placeholder="请输入额定功率"
          />
        </el-form-item>
        <el-form-item label="额定视在功率">
          <el-input
            v-model="protoTurbineObj.partsMessage.ratedApparentPower"
            placeholder="请输入额定视在功率"
          />
        </el-form-item>
        <el-form-item label="额定电流">
          <el-input
            v-model="protoTurbineObj.partsMessage.ratedCurrent"
            placeholder="请输入额定电流"
          />
        </el-form-item>
        <el-form-item label="额定电压">
          <el-input
            v-model="protoTurbineObj.partsMessage.ratedVoltage"
            placeholder="请输入额定电压"
          />
        </el-form-item>
        <el-form-item label="轮毂中心距地面高度">
          <el-input
            v-model="protoTurbineObj.partsMessage.hubCenterHeight"
            placeholder="请输入轮毂中心距地面高度"
          />
        </el-form-item>
        <el-form-item label="额定风速">
          <el-input
            v-model="protoTurbineObj.partsMessage.ratedWindSpeed"
            placeholder="请输入额定风速"
          />
        </el-form-item>
        <el-form-item label="切入风速">
          <el-input v-model="protoTurbineObj.partsMessage.cutInWind" placeholder="请输入切入风速" />
        </el-form-item>
        <el-form-item label="切出风速">
          <el-input
            v-model="protoTurbineObj.partsMessage.cutOutWind"
            placeholder="请输入切出风速"
          />
        </el-form-item>
        <el-form-item label="最大感性无功容量">
          <el-input
            v-model="protoTurbineObj.partsMessage.maxPerceptualCapacity"
            placeholder="请输入最大感性无功容量"
          />
        </el-form-item>
        <el-form-item label="最大容性无功容量">
          <el-input
            v-model="protoTurbineObj.partsMessage.maxCompatibilityCapacity"
            placeholder="请输入最大容性无功容量"
          />
        </el-form-item>
        <el-form-item label="高压侧短路容量">
          <el-input
            v-model="protoTurbineObj.partsMessage.highVoltageShortCircuitCapacity"
            placeholder="请输入风电机组变压器高压侧短路容量"
          />
        </el-form-item>
        <el-form-item label="塔筒高度">
          <el-input v-model="protoTurbineObj.partsMessage.hubHeight" placeholder="请输入塔筒高度" />
        </el-form-item>
        <el-form-item label="叶片长度">
          <el-input
            v-model="protoTurbineObj.partsMessage.bladeLength"
            placeholder="请输入叶片长度"
          />
        </el-form-item>
        <el-form-item label="额定转速">
          <el-input
            v-model="protoTurbineObj.partsMessage.ratedSpeed"
            placeholder="请输入额定转速"
          />
        </el-form-item>
        <el-form-item label="塔筒频率-固有频率">
          <el-input
            v-model="protoTurbineObj.partsMessage.hubRate"
            placeholder="请输入塔筒频率-固有频率"
          />
        </el-form-item>
        <el-form-item label="叶片频率-挥舞一阶">
          <el-input
            v-model="protoTurbineObj.partsMessage.waveOneStep"
            placeholder="请输入叶片频率-挥舞一阶"
          />
        </el-form-item>
        <el-form-item label="叶片频率-摆阵一阶">
          <el-input
            v-model="protoTurbineObj.partsMessage.shimmyOneStep"
            placeholder="请输入叶片频率-摆阵一阶"
          />
        </el-form-item>
      </el-form>
      <div style="text-align: right">
        <el-button size="small" @click="resetForm('ruleForm')">取消</el-button>
        <el-button type="primary" size="small" @click="onSubmit">
          {{ isAdd ? "新增" : "修改" }}
        </el-button>
        <el-button
          v-if="!isAdd"
          type="success"
          size="small"
          @click="gotoContent(protoTurbineObj.id)"
        >
          更多参数
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="scss">
.el-table .warning-row {
  background: #f4f6f7;
}
.el-table thead.is-group th {
  background: #d4e0f3;
}

.filter-form .filter-bar {
  margin-bottom: 10px;
}
</style>

<style lang="scss" scoped>
.demo-form-inline {
  max-height: 400px;
  overflow-y: auto;
  .el-input,
  .el-select {
    width: 230px;
  }
  ::v-deep(.el-form-item__label) {
    font-size: 12px;
  }
}
</style>
