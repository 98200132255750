<template>
  <div id="farmInfoContent" class="col-12 farmInfo-content">
    <div class="farmInfo-top widget-content-area">
      <el-row class="row" :gutter="24">
        <el-col :span="8">
          <p><label>风场编号：</label><span>{{ farm.farmCode }}</span></p>
          <p><label>业主名称：</label><span>{{ farm.ownerName }}</span></p>
          <p><label>业主简称：</label><span>{{ farm.ownerEasyName }}</span></p>
          <p><label>装机总数：</label><span>{{ farm.installedNum || 0 }}</span></p>
        </el-col>
        <el-col :span="8">
          <!-- <p><label>容量：</label><span>{{ farm.turbines.length }}</span></p> -->
          <p><label>经度：</label><span>{{ farm.longitude }}</span></p>
          <p><label>纬度：</label><span>{{ farm.latitude }}</span></p>
        </el-col>
        <el-col :span="8">
          <p><label>省份：</label><span>{{ farm.province }}</span></p>
          <p><label>城市：</label><span>{{ farm.city }}</span></p>
          <p><label>地址：</label><span>{{ farm.address }}</span></p>
        </el-col>
      </el-row>
    </div>
    <div class="farmInfo-bottom widget-content-area">
      <ul class="tab-lists">
        <li :class="{ active: curTab === 'turbine' }" @click="curTab = 'turbine'">
          风机信息
        </li>
        <li :class="{ active: curTab === 'server' }" @click="curTab = 'server'">
          设备信息
        </li>
      </ul>
      <div id="turbineTable" class="turbine-table">
        <el-table
          v-show="curTab === 'turbine'"
          :data="turbineDatas"
          style="width: 100%"
          size="mini"
        >
          <template v-for="(i, index) in listInfo">
            <el-table-column :key="index" :label="i.title" :min-width="i.labelWidth" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                {{ scope.row[i.name] }}
              </template>
            </el-table-column>
          </template>
        </el-table>
        <el-table
          v-show="curTab === 'server'"
          v-fit-columns
          :data="serverDatas"
          style="width: 100%"
          size="mini"
        >
          <el-table-column
            v-for="(item, index) in listServer"
            :key="index + ''"
            :prop="item.name"
            :label="item.title"
            :min-width="item.labelWidth"
            :show-overflow-tooltip="true"
          />
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { farmService } from '@/service';

export default {
  props: ['farmCode'],
  data() {
    return {
      turbineDatas: [],
      serverDatas: [],
      listInfo: [
        { title: '业主机位号', name: 'ownerTurbineName', labelWidth: '110px' },
        {
          title: '内部机位号',
          name: 'innerTurbineName',
          labelWidth: '120px',
        },
        { title: '机型', name: 'typeName', labelWidth: '110px' },
        { title: '馈线名称', name: 'loopName', labelWidth: '110px' },
        { title: '馈线顺序号', name: 'loopOrder', labelWidth: '110px' },
        { title: '主控IP', name: 'plcIp', labelWidth: '110px' },
        { title: '采集协议', name: 'protocolType', labelWidth: '110px' },
        { title: '端口号', name: 'protocolPort', labelWidth: '110px' },
        { title: '设备ID', name: 'modbusDeviceId', labelWidth: '110px' },
        { title: '通信点表', name: 'pointName', labelWidth: '110px' },
        { title: '额定功率', name: 'ratedPower', labelWidth: '110px' },
        { title: '变流器', name: 'converter', labelWidth: '110px' },
        { title: '齿轮箱', name: 'gearbox', labelWidth: '110px' },
        { title: '发电机', name: 'alternator', labelWidth: '110px' },
        { title: '变桨', name: 'pitch', labelWidth: '110px' },
        { title: '经度', name: 'longitude', labelWidth: '110px' },
        { title: '纬度', name: 'latitude', labelWidth: '110px' },
        { title: '设备名称', name: 'deviceNameQt', labelWidth: '110px' },
        { title: '设备IP', name: 'deviceIpQt', labelWidth: '110px' },
        { title: '采集协议', name: 'protocolTypeQt', labelWidth: '110px' },
        { title: '端口号', name: 'protocolPortQt', labelWidth: '110px' },
        { title: '设备ID', name: 'modbusDeviceIdQt', labelWidth: '110px' },
        { title: '通信点表', name: 'pointNameQt', labelWidth: '120px' },
      ],
      listServer: [
        { title: '服务器名称', name: 'serverName', labelWidth: '110px' },
        { title: '操作系统', name: 'serverOs', labelWidth: '110px' },
        { title: 'IP', name: 'serverIp', labelWidth: '180px' },
        { title: 'MAC', name: 'mac', labelWidth: '180px' },
        { title: '配置', name: 'serverConfig', labelWidth: '80px' },
        { title: '类型 ', name: 'serverModel', labelWidth: '120px' },
        { title: '反馈码', name: 'feedBackCode' },
        { title: '厂家', name: 'manufactor' },
        { title: '三一编号', name: 'sanyCode' },
        { title: '授权', name: 'empower' },
        { title: '安装人员', name: 'installPersion' },
        { title: '安装时间', name: 'installDate', labelWidth: '160px' },
        { title: '备注', name: 'remark' },
      ],
      curTab: 'turbine',
    };
  },
  computed: {
    farm() {
      const farms = this.$store.state.farm.list.filter((item) => item.pinyinCode === this.farmCode);
      return farms.length > 0 ? farms[0] : null;
    },
  },
  watch: {
    farmCode(n, o) {
      const farms = this.$store.state.farm.list.filter((ent) => ent.pinyinCode === n);
      this.farm = farms.length > 0 ? farms[0] : null;
      this.turbineAllInOneByFarm();
      this.getserverByFarmId();
    },
  },
  mounted() {
    this.turbineAllInOneByFarm();
    this.getserverByFarmId();
  },
  methods: {
    async turbineAllInOneByFarm() {
      const res = await farmService.turbineAllInOneByFarm(this.farm.pinyinCode);
      this.turbineDatas = res;
    },
    async getserverByFarmId() {
      const res = await farmService.getFarmServer(this.farm.pinyinCode);
      this.serverDatas = res;
    },
  },

};
</script>

<style lang="scss" scoped>
  .farmInfo-content{
    height: calc(100% - 5px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 10px;
  }
  .farmInfo-top{
    height: 148px;
    line-height: 32px;
    overflow-y: auto;
    padding: 10px 20px;
    margin-bottom: 15px;
    flex-shrink: 0;
    // box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.0901961), 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06);
    label{
      width: 100px;
      margin-bottom: 0 !important;
      font-size: 15px;
      color: #acb0c3;
      letter-spacing: 1px;
    }
  }

  .farmInfo-bottom{
    width: 100%;
    padding: 10px 10px 10px 20px;
    flex: 1;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    .tab-lists{
      height: 35px;
      margin-bottom: 10px;
      padding: 0;
      li{
        display: inline-block;
        border-color: transparent;
        color: #515365;
        background-color: transparent;
        padding-top: 5px;
        padding-bottom: 10px;
        margin-right: 15px;
        cursor: pointer;
        &.active{
          border-bottom: 1px solid #5c1ac3;
          color: #5c1ac3;
        }
      }
    }
    .turbine-table{
      flex: 1;
      width: 100%;
      overflow-y: hidden;
      display: flex;
      .el-table {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      ::v-deep .el-table__body-wrapper {
        flex: 1;
        overflow-y: auto;
      }
      // ::v-deep .el-table__body-wrapper{
      //   height: calc(100% - 36px)!important;
      //   overflow-y: scroll!important;
      // }
    }
  }

</style>
