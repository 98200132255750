<template>
  <div class="fault-content">
    <div class="filter-form" style="margin-bottom:10px">
      <!--模糊搜索-->
      <el-row :gutter="24">
        <el-col :span="8">
          <el-date-picker
            v-model="dateRangeStr"
            type="daterange"
            size="mini"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="dateRangeStrChange"
          />
        </el-col>
        <el-col :span="6">
          <el-input placeholder="风机 故障码 部件..." v-model="keyword" size="mini">
            <el-button slot="append" icon="el-icon-search" size="mini" />
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-button type="success" @click="exportFaults" size="mini">导出</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-responsive widget-content-area">
      <div class="dataTables_wrapper table-sticky-wrapper">
        <table
          v-loading="isLoading"
          class="dataTable table table-hover"
          style="width:100%"
        >
          <thead>
            <tr>
              <th class="sorting" :class="setOrderStyle('id')" @click="setOrder('id')">
                <span style="width:60px">编号</span>
              </th>
              <th class="sorting" :class="setOrderStyle('farmName')" @click="setOrder('farmName')">
                <span style="width:120px">风场名称</span>
              </th>
              <th class="sorting" :class="setOrderStyle('turbineName')" @click="setOrder('turbineName')">
                <span style="width:75px">风机号</span>
              </th>
              <th class="sorting" :class="setOrderStyle('statusCode')" @click="setOrder('statusCode')">
                <span style="width:75px">故障码</span>
              </th>
              <th class="sorting" :class="setOrderStyle('faultDes')" @click="setOrder('faultDes')">
                <span style="min-width:210px">故障名称</span>
              </th>
              <th class="sorting" :class="setOrderStyle('turbineType')" @click="setOrder('turbineType')">
                <span style="width:100px">机型</span>
              </th>

              <th class="sorting" :class="setOrderStyle('faultPart')" @click="setOrder('faultPart')">
                <span style="width:75px">部件</span>
              </th>
              <th class="sorting" :class="setOrderStyle('faultStartTime')" @click="setOrder('faultStartTime')">
                <span style="width:150px">故障开始</span>
              </th>
              <th class="sorting" :class="setOrderStyle('faultEndTime')" @click="setOrder('faultEndTime')">
                <span style="width:150px">故障结束</span>
              </th>
              <th class="sorting" :class="setOrderStyle('updateTime')" @click="setOrder('updateTime')">
                <span style="width:150px">更新时间</span>
              </th>
              <th class="sorting" :class="setOrderStyle('faultTime')" @click="setOrder('faultTime')">
                <span style="width:150px">故障时长</span>
              </th>
              <th class="sorting" :class="setOrderStyle('stopTime')" @click="setOrder('stopTime')">
                <span style="width:150px">停机时长</span>
              </th>
              <th class="sorting" :class="setOrderStyle('responseTime')" @click="setOrder('responseTime')">
                <span style="width:150px">响应时长</span>
              </th>
              <th class="sorting" :class="setOrderStyle('handleTime')" @click="setOrder('handleTime')">
                <span style="width:150px">处理时长</span>
              </th>
              <th class="sorting" :class="setOrderStyle('waitTime')" @click="setOrder('waitTime')">
                <span style="width:150px">等待时长</span>
              </th>
              <th class="sorting" :class="setOrderStyle('faultStyle')" @click="setOrder('faultStyle')">
                <span style="width:100px">故障类型</span>
              </th>
              <th class="text-center">
                <span style="width:50px;display:inline-block">分析</span>
              </th>
              <th class="text-center">
                <span style="width:50px;display:inline-block">下载</span>
              </th>
            </tr>
          </thead>
          <tbody v-if="pageData.data.length > 0">
            <tr v-for="item in pageData.data" :key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.farmName }}</td>
              <td>{{ item.turbineName }}</td>
              <td>{{ item.statusCode }}</td>
              <td>{{ item.faultDes }}</td>
              <td>{{ item.turbineType }}</td>
              <td>{{ item.faultPart }}</td>
              <td>{{ item.faultStartTime }}</td>
              <td>{{ item.faultEndTime }}</td>
              <td>{{ item.updateTime }}</td>
              <td>{{ durationFormat(item.faultTime) }}</td>
              <td>{{ durationFormat(item.stopTime) }}</td>
              <td>{{ durationFormat(item.responseTime) }}</td>
              <td>{{ durationFormat(item.handleTime) }}</td>
              <td>{{ durationFormat(item.waitTime) }}</td>
              <td :class="{ fault0: !item.faultStyle, fault1: item.faultStyle }">
                {{ faultStyleList[item.faultStyle] }}
              </td>
              <td class="text-center pointer">
                <ul class="table-controls pointer">
                  <li @click="goToTool(item)">
                    <a>
                      <ActivityIcon class="text-primary" />
                    </a>
                  </li>
                </ul>
              </td>
              <td class="text-center pointer">
                <span @click="downloadData(item)"><DownloadIcon class="text-primary" /></span>
                <div v-if="item.progress" class="browser-list">
                  <div class="w-browser-details">
                    <div class="w-browser-info">
                      <p>{{ item.progress }}</p>
                      <p class="browser-count">
                        {{ downloadPrecent }}%
                      </p>
                    </div>
                    <div class="w-browser-stats">
                      <div class="progress br-30">
                        <div
                          class="progress-bar bg-gradient-primary"
                          role="progressbar"
                          :style="{ width: downloadPrecent + '%' }"
                          :aria-valuenow="downloadPrecent"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="13">
                <el-empty description="暂无数据" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Pagination :page-data="pageData" :goto-page="gotoPage" />
  </div>
</template>

<script>
import { farmService as service, faultService, otherService } from '@/service';
import { uikit } from '@/utils';
import moment from 'moment';
import Pagination from '@/components/Pagination';
import {
  ActivityIcon, DownloadIcon,
} from 'vue-feather-icons';

export default {
  components: {
    Pagination,
    ActivityIcon,
    DownloadIcon,
  },
  props: ['farmCode'],
  data() {
    return {
      keyword: '',
      faultDatas: [],
      isLoading: true,
      selectedFiles: [],
      orderModel: {
        field: 'faultStartTime',
        sort: 'desc',
      },
      dateRangeStr: '',
      hasComment: false,
      pageData: {
        data: [],
        limit: 20,
        page: 1,
        total: 0,
      },
      progress: '',
      downloadStatus: null,
      faultStyleList: ['复位处理', '登塔处理'],
    };
  },
  computed: {
    farm() {
      const farms = this.$store.state.farm.list.filter((item) => item.pinyinCode === this.farmCode);
      return farms.length > 0 ? farms[0] : null;
    },
    downloadPrecent() {
      if (this.downloadStatus) {
        const { progress, total } = this.downloadStatus;
        return Math.floor((progress / total) * 100);
      }
      return 0;
    },

  },
  watch: {
    farmCode(n, o) {
      const farms = this.$store.state.farm.list.filter((ent) => ent.pinyinCode === n);
      this.farm = farms.length > 0 ? farms[0] : null;
      this.getFaultDatas();
    },
    keyword(newValue, oldValue) {
      debugger;
      if (newValue !== oldValue) {
        this.getFaultDatas(1);
      }
    },
  },
  mounted() {
    this.initDateRangeStr();
    this.getFaultDatas();
  },
  methods: {
    initDateRangeStr() {
      const dateFrom = moment().subtract(30, 'd').format('YYYY-MM-DD');
      const dateTo = moment().format('YYYY-MM-DD');
      this.dateRangeStr = [dateFrom, dateTo];
    },
    dateRangeStrChange() {
      console.log(this.dateRangeStr);
    },
    prepareSearchOptions(page = 1) {
      let from = '';
      let to = '';
      if (this.dateRangeStr) {
        const dateRanges = this.dateRangeStr;
        from = dateRanges[0];
        to = moment(dateRanges[dateRanges.length - 1]).add(1, 'd').format('YYYY-MM-DD');
      }
      const { field, sort } = this.orderModel;
      const postData = {
        from,
        to,
        keyword: this.keyword,
        hasComment: this.hasComment,
        orders: [[field, sort]],
        farmNames: [this.farm.farmName],
        faultTypes: [],
        page,
      };
      return postData;
    },
    getFaultDatas(page = 1) {
      faultService.searchFaultAnaly(this.prepareSearchOptions(page)).then((data) => {
        this.isLoading = false;
        const promiseList = [];
        data.data.forEach((item) => {
          item.faultStartTime = moment(item.faultStartTime).format('YYYY-MM-DD HH:mm:ss');
          item.faultEndTime = moment(item.faultEndTime).format('YYYY-MM-DD HH:mm:ss');
          let singlePromise = [];
          const options = {
            farms: [item.pinyinCode],
            turbines: [item.turbineName],
            types: [],
            from: moment(item.faultStartTime).format('YYYY-MM-DD'),
            to: moment(item.faultEndTime).add(1, 'd').format('YYYY-MM-DD'),
          };
          if (item.faultFileIds && item.faultFileIds.length > 0) {
            faultService.getFaultAnalyFile({ faultFileIds: item.faultFileIds }).then((fault) => {
              item.faultCosKeyList = fault.map((r) => r.cosKey);
              options.faultCosKeyList = item.faultCosKeyList;
              singlePromise = service.peekCosFile(options).then((result) => {
                item.peekResult = result;
              });
              promiseList.push(singlePromise);
            });
          } else {
            singlePromise = service.peekCosFile(options).then((result) => {
              item.peekResult = result;
            });
            promiseList.push(singlePromise);
          }
        });
        return Promise.all(promiseList).then(() => {
          this.pageData = data;
          this.isLoading = false;
        });
      });
    },
    gotoPage(i) {
      this.getFaultDatas(i);
    },
    setOrderStyle(filed) {
      if (this.orderModel.field === filed) {
        if (this.orderModel.sort === 'asc') {
          return 'sorting_asc';
        }
        return 'sorting_desc';
      }
      return '';
    },
    setOrder(field) {
      if (this.orderModel.field === field) {
        if (this.orderModel.sort === 'asc') {
          this.orderModel.sort = 'desc';
        } else {
          this.orderModel.sort = 'asc';
        }
      } else {
        this.orderModel.field = field;
        this.orderModel.sort = 'desc';
      }
      this.getFaultDatas(1);
    },
    goToTool(item) {
      const routeData = this.$router.resolve({
        name: 'analyze-tool',
        query: {
          firstFaultId: item.id,
          turbineName: item.turbineName,
          farmPinyin: item.pinyinCode,
          time: item.faultStartTime,
          faultId: item.id,
        },
      });
      window.open(`//${window.location.host}/micro-apps/tool${routeData.href}`, '_blank');
    },
    // 下载
    downloadFaults() {
      if (this.selectedFiles.length === 0) {
        uikit.error('请选择故障列表');
        return;
      }
      faultService.downloadFaults({ fileList: this.selectedFiles }).then((result) => {
        console.log(result);
        if (result.file) {
          window.open(`/api/v3/temp/${result.file}`);
        }
      });
    },
    downloadData(item) {
      if (item.peekResult && item.peekResult.count > 0) {
        const obj = {
          farms: [item.pinyinCode],
          turbines: [item.turbineName],
          types: [],
          from: moment(item.faultStartTime).format('YYYY-MM-DD'),
          to: moment(item.faultEndTime).add(1, 'd').format('YYYY-MM-DD'),
          faultCosKeyList: item.faultCosKeyList,
        };
        service.downloadCosFile(obj).then((res) => {
          const { count, jobId } = res;
          // item.progress = `正在下载 ${0 / count}`
          if (jobId) {
            this.$set(item, 'progress', `正在下载 ${0 / count}`);
            this.checkDownloadJob(item, jobId);
          } else {
            console.log(res);
            uikit.error('没有文件可以下载！');
          }
        });
      } else {
        uikit.error('没有文件可以下载！');
      }
    },
    checkDownloadJob(item, jobId) {
      service.checkDownloadJob(jobId).then(({
        progress, total, result, status,
      }) => {
        this.downloadStatus = {
          progress, total, result, status,
        };
        if (progress === total) {
          this.$set(item, 'progress', `下载完成(${progress}/${total})，正在压缩打包...`);
        } else {
          this.$set(item, 'progress', `正在下载 ${progress}/${total}`);
        }
        if (result) {
          // item.progress = ''
          this.$set(item, 'progress', '');
          // window.open(`/api/v3/temp/${result}`);
          service.updatePackageFileStatus({
            jobId,
            url: result,
            status,
          });
          otherService.downLoadFile(result);
        } else {
          setTimeout(() => {
            this.checkDownloadJob(item, jobId);
          }, 5 * 1000);
        }
      });
    },
    // 导出
    exportFaults() {
      const searchOptions = this.prepareSearchOptions();
      if (this.pageData.data.length === 0) {
        uikit.error('请选择故障列表');
      } else {
        faultService.exportAnalyFaults(searchOptions).then((result) => {
          console.log(result);
          if (result.file) {
            window.open(`/api/v3/temp/${result.file}`);
          }
        });
      }
    },

    durationFormat(seconds) {
      return moment.duration(seconds, 'seconds').format('h[小时]m[分钟]');
    },

  },

};
</script>

<style lang="scss" scoped>
.fault-content{
  padding: 0px 5px 10px;
  height: 100%;
  .table-responsive{
    height: calc(100% - 110px);
    overflow-y: auto;
    padding: 10px 20px;
    width: 100%;
    .dataTables_wrapper{
      width: 100%;
      overflow-x: auto;
    }
    tbody{
      td{
        &.fault0{
          color: #28a745;
        }
        &.fault1{
          color: #dc3545;
        }
      }
    }
  }
}

</style>
